<div class="row">
    <div class="col" *ngFor="let item of items">
        <div class="single-funfact" [ngClass]="{ '' : !item.selected, 'active' : item.selected }"
            (click)="selectMonth(item)">
            <div class="icon">
                <i class='bx bx-calendar'></i>
            </div>
            <h3>{{ item.name }}</h3>
        </div>
    </div>
</div>