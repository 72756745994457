<section class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Descubrir cursos</span>
            <h2 class="title">Nuestros cursos populares en línea</h2>
            <a [routerLink]="['/courses']" class="default-btn"><i
                    class='bx bx-show-alt icon-arrow before'></i><span class="label">Todos los cursos</span><i
                    class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let course of courses">
                <app-course-card [course]="course"></app-course-card>
            </div>
        </div>
        <div class="row text-center">
            <h6><a [routerLink]="['/courses']"> Ver todos los cursos </a></h6>
        </div>
    </div>
</section>