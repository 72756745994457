import { Country } from "./country.model";

interface Plan {
    id: number;
    next_renovation_at: string;
    type: string;
    is_active: boolean;
}
interface Avatar {
    id: number;
    file_name: string;
    mime_type: string;
    original_url: string;
}

export class User {
    private _id: number;
    private _first_name: string;
    private _last_name: string;
    private _second_last_name: string;
    private _role: string;
    private _document_number: string | null = null;
    private _document_type: string | null = null;
    private _phone: string | null = null;
    private _phone_code: string | null = null;
    private _address: string | null = null;
    private _email: string;
    private _date_of_birth: Date | null = null;
    private _plan: Plan;
    private _avatar: Avatar;
    private _country_id: number | null = null;
    private _country: Country | null = null;
    private _gender: string = null;

    constructor(profile) {
        this._id = profile.id;
        this._first_name = profile.first_name;
        this._last_name = profile.last_name;
        this._second_last_name = profile.second_last_name;
        this._role = profile.role;
        this._document_number = profile.document_number;
        this._document_type = profile.document_type;
        this._email = profile.email;
        this._date_of_birth = profile.date_of_birth;
        this._phone = profile.phone;
        this._phone_code = profile.phone_code;
        this._address = profile.address;
        this._country_id = profile.country_id;
        this._gender = profile.gender;

        if (profile.plan) {
            this._plan = {
                id: profile.plan.id,
                next_renovation_at: profile.plan.next_renovation_at,
                type: profile.plan.type,
            } as Plan;
        }

        if (profile.avatar) {
            this._avatar = {
                id: profile.avatar.id,
                file_name: profile.avatar.file_name,
                mime_type: profile.avatar.mime_type,
                original_url: profile.avatar.original_url,
            } as Avatar;
        }

        if (profile.country) {
            this._country = {
                id: profile.country.id,
                name: profile.country.name,
                phone_code: profile.country.phone_code,
                code: profile.country.code,
            } as Country;
        }
    }

    get id() {
        return this._id;
    }

    get first_name() {
        return this._first_name;
    }

    get last_name() {
        return this._last_name;
    }

    get second_last_name() {
        return this._second_last_name;
    }

    get document_number() {
        return this._document_number;
    }

    get document_type() {
        return this._document_type;
    }

    get name() {
        return `${this._first_name} ${this._last_name}`;
    }

    get plan() {
        return this._plan;
    }

    get country() {
        return this._country;
    }

    get country_id() {
        return this._country_id;
    }

    get date_of_birth() {
        return this._date_of_birth;
    }

    get documentNumber() {
        return this._document_number;
    }

    get documentType() {
        return this._document_type;
    }

    get email() {
        return this._email;
    }

    get phone() {
        return this._phone;
    }

    get phoneCode() {
        return this._phone_code;
    }

    get address() {
        return this._address;
    }

    get gender() {
        return this._gender;
    }

    get avatarUrl() {
        if (this._avatar && this._avatar.original_url) {
            let original_url = this._avatar.original_url;
            if (
                original_url.indexOf("http://") !== 0 &&
                original_url.indexOf("https://") !== 0
            ) {
                original_url = `http://${original_url}`;
            }
            return original_url;
        } else {
            return `assets/img/avatar.jpg`;
        }
        return ``;
    }

    setAvatar(avatar: any) {
        this._avatar = avatar;
    }
}
