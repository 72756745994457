import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { Payment } from "../models/payment.model";
import { PaginatorService } from "./paginator.service";
import { StoreService } from "./store.service";
import { AlertService } from "./alert.service";
import { Course } from "../models/course.model";
import { Group } from "../models/group.model";
import { catchError } from "rxjs/operators";
import { PaymentItem } from "../models/paymentItem.model";
import { Router } from "@angular/router";
import { v4 as uuidv4 } from "uuid";

@Injectable({
    providedIn: "root",
})
export class PaymentService {
    constructor(
        private http: HttpClient,
        private storeService: StoreService,
        private alertService: AlertService,
        private router: Router
    ) {}

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getTokenBearer()}`,
        }),
    };

    getCart() {
        return this.storeService.getCart();
    }

    getPendingPayments(): Payment[] {
        return this.storeService.getPendingPayments();
    }

    removeItem(model_id: number) {
        let url = `${environment.apiUrl}/payment-items?model_id=${model_id}`;

        return this.http.delete<any>(url, this.httpOptions);
    }

    removePendingPayment(payment_id: number) {
        let url = `${environment.apiUrl}/my-ecommerce-payments/${payment_id}`;

        return this.http.delete<any>(url, this.httpOptions).pipe(
            catchError((error) => {
                console.log(error);
                this.alertService.showErrors(error);
                return throwError(error);
            })
        );
    }

    addToCart(course: Course, group?: Group) {
        if (course.modality !== "recorded" && group === null) {
            this.alertService.showAlert(
                this.alertService.typeInfo,
                this.alertService.purchaseNotSelectedGroup
            );
            return;
        }
        let payments = this.getPendingPayments();
        if (!payments) {
            payments = [];
        }

        let itemFind = false;
        let payment_id = null;
        let isReplace = false;

        payments.forEach((payment) => {
            payment.items.forEach((item) => {
                if (item.model_id === course.id) {
                    itemFind = true;

                    if (
                        item.variant &&
                        group &&
                        item.variant.model_id !== group.id
                    ) {
                        itemFind = false;
                        payment_id = payment.id;
                        item.variant.model_id = group.id;
                        item.variant.group = group;
                        isReplace = true;
                    }
                }
            });
        });

        if (itemFind) {
            this.alertService.showAlert(
                this.alertService.typeInfo,
                this.alertService.purchasePreviouslyAdded
            );
            return;
        }

        let item: PaymentItem = {
            payment_id: payment_id,
            payable: null,
            slug: null,
            model_id: course.id,
            model_type: "courses",
            title: course.title,
            amount: course.amount,
            coupon_id: course.coupon_id,
            coupon: course.coupon,
            quantity: 1,
            variant: group
                ? {
                      model_id: group.id,
                      model_type: "groups",
                  }
                : null,
        };

        if (this.getTokenBearer()) {
            this.persistPendingPayments({
                payment_id: payment_id,
                model_id: course.id,
                model_type: "courses",
                title: course.title,
                amount: course.amount,
                coupon_id: course.coupon_id,
                coupon: course.coupon,
                quantity: 1,
                variant: group
                    ? {
                          model_id: group.id,
                          model_type: "groups",
                      }
                    : null,
                replace: true,
            });
        } else {
            if (!isReplace) {
                payments.push({
                    id: null,
                    items: [item],
                    payable: null,
                    currency: null,
                    status: null,
                    confirmed_at: null,
                    uuid: uuidv4(),
                });
            }
            this.storeService.setPendingPayments(payments);
            this.alertService.showAlert(
                this.alertService.typeSuccess,
                this.alertService.purchaseSuccessfullyAdded
            );
        }
        setTimeout(() => {
            this.router.navigate(["/cart"]);
        }, 1200);

        return null;
    }

    persistPendingPayments(item: any, token: string = null) {
        if (!item.payment_id) {
            delete item.payment_id;
        }
        let payments = this.getPendingPayments();

        this.storePendingPayment(item, token).subscribe((response) => {
            payments.push(response.data);

            const uniqueValuesSet = new Set();

            payments = payments.filter((obj) => {
                // check if name property value is already in the set
                const isPresentInSet = uniqueValuesSet.has(obj.id);

                // add name property value to Set
                uniqueValuesSet.add(obj.id);

                // return the negated value of
                // isPresentInSet variable
                return !isPresentInSet;
            });

            this.storeService.setPendingPayments(payments);
            this.alertService.showAlert(
                this.alertService.typeSuccess,
                this.alertService.purchaseSuccessfullyAdded
            );
        });
    }

    persistPendingCart(token: string = null) {
        let payments = this.getPendingPayments();

        if (payments.length === 0) {
            return;
        }

        let items = [];
        payments.forEach((payment) => {
            payment.items.forEach((item) => {
                items.push({ ...item, uuid: payment.uuid });
            });
        });

        this.syncPendingPayments({ payments: items }, token).subscribe(
            (response) => {
                console.log(response);
            }
        );
    }

    persistCart(items: any, token: string = null) {
        this.generatePayment(items, token).subscribe((response) => {
            if (response && response.data.items) {
                this.storeService.setCart(response.data.items);
                this.alertService.showAlert(
                    this.alertService.typeSuccess,
                    this.alertService.purchaseSuccessfullyAdded
                );
            }
        });
    }

    storePendingPayment(
        data: {
            model_id: number;
            model_type: string;
            title: string;
            amount: number;
            coupon_id: number;
            quantity: number;
            variant: { model_id: number; model_type: string } | null;
            replace: boolean;
        },
        token: string = null
    ): Observable<{
        data: Payment;
    }> {
        if (token) {
            this.httpOptions = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            };
        }

        let url = `${environment.apiUrl}/payments-replace`;

        return this.http.post<any>(url, data, this.httpOptions).pipe(
            catchError((error) => {
                console.log(error);
                this.alertService.showErrors(error);
                return throwError(error);
            })
        );
    }

    syncPendingPayments(
        data,
        token: string = null
    ): Observable<{
        data: Payment;
    }> {
        if (token) {
            this.httpOptions = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            };
        }

        let url = `${environment.apiUrl}/payments-sync`;

        return this.http.post<any>(url, data, this.httpOptions).pipe(
            catchError((error) => {
                console.log(error);
                this.alertService.showErrors(error);
                return throwError(error);
            })
        );
    }

    generatePayment(
        items: any[],
        token: string = null
    ): Observable<{
        data: Payment;
    }> {
        if (token) {
            this.httpOptions = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            };
        }

        let url = `${environment.apiUrl}/payments`;

        return this.http
            .post<any>(
                url,
                {
                    items,
                },
                this.httpOptions
            )
            .pipe(
                catchError((error) => {
                    console.log(error);
                    this.alertService.showErrors(error);
                    return throwError(error);
                })
            );
    }

    storePaymentInscription(data: { payment_id: number }): Observable<{
        payment: Payment;
    }> {
        let url = `${environment.apiUrl}/payments/inscriptions`;

        return this.http.post<any>(url, data, this.httpOptions).pipe(
            catchError((error) => {
                console.log(error);
                this.alertService.showErrors(error);
                return throwError(error);
            })
        );
    }

    showPayment(payment_id: number) {
        let url = `${environment.apiUrl}/payments/${payment_id}`;

        return this.http.get<any>(url, this.httpOptions);
    }

    getTokenBearer() {
        return this.storeService.getTokenBearer();
    }
}
