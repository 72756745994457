<app-header-style-one></app-header-style-one>

<div class="my-dashboard-area">
    <div class="container">
        <app-profile-navigation></app-profile-navigation>
        <div class="myDashboard-content pb-70">
            <form class="edit-account" [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <legend>Detalles de la cuenta</legend>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input type="file" #fileInput id="uploadFile" (change)="uploadFile($event)"
                                name="uploadFile" accept="image/*" class="custom-file-input" />
                            <div class="single-feedback-item">
                                <div class="info">
                                    <img [src]="avatarUrl" class="shadow rounded-circle" alt="Imagen de perfil">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Nombre <span class="required">*</span></label>
                            <input formControlName="first_name" type="text" class="form-control uppercase"
                                [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">
                            <p *ngIf="f.first_name.invalid && f.first_name.touched" class="validation-error">Nombre no
                                válido</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Apellido paterno <span class="required">*</span></label>
                            <input formControlName="last_name" type="text" class="form-control uppercase"
                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                            <p *ngIf="f.last_name.invalid && f.last_name.touched" class="validation-error">Apellido
                                paterno no
                                válido</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Apellido materno <span class="required">*</span></label>
                            <input formControlName="second_last_name" type="text" class="form-control uppercase"
                                [ngClass]="{ 'is-invalid': submitted && f.second_last_name.errors }">
                            <p *ngIf="f.second_last_name.invalid && f.second_last_name.touched"
                                class="validation-error">
                                Apellido materno no
                                válido</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Email <span class="required">*</span></label>
                            <input formControlName="email" type="email" class="form-control lowercase"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                            <p *ngIf="f.email.invalid && f.email.touched" class="validation-error">Email no válido</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Fecha de Nacimiento <span class="required">*</span></label>
                            <input formControlName="date_of_birth" type="date" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.date_of_birth.errors }">
                            <p *ngIf="f.date_of_birth.invalid && f.date_of_birth.touched" class="validation-error">Fecha
                                no válida</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Género <span class="required">*</span></label>
                            <div class="select-box">
                                <select class="form-select" formControlName="gender">
                                    <option value="M">Masculino</option>
                                    <option value="F">Femenino</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Tipo de documento <span class="required">*</span></label>
                            <div class="select-box">
                                <select class="form-select" formControlName="document_type">
                                    <option *ngFor="let type of ['DNI','CE']" [value]="type">
                                        {{ type }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Número de documento <span class="required">*</span></label>
                            <input formControlName="document_number" type="text" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.document_number.errors }">
                            <p *ngIf="f.document_number.invalid && f.document_number.touched" class="validation-error">
                                Mínimo 8 caracteres</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>País <span class="required">*</span></label>
                            <div class="select-box">
                                <select class="form-select" formControlName="country_id">
                                    <option *ngFor="let country of countries" [value]="country.id">{{ country?.name }}
                                        ({{country?.phone_code}})</option>
                                </select>
                                <p *ngIf="f.country_id.invalid && f.country_id.touched" class="validation-error">
                                    Selección de país no válida</p>
                                <p *ngIf="!countryIsValid" class="validation-error">Seleccione un país para guardar</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Teléfono/Celular <span class="required">*</span></label>
                            <input formControlName="phone" type="text" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                            <p *ngIf="f.phone.invalid && f.phone.touched" class="validation-error">Teléfono/celular no
                                válido</p>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn">
                            <i class='bx bx-save icon-arrow before'></i>
                            <span class="label">Actualizar perfil</span>
                            <i class="bx bx-save icon-arrow after"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>