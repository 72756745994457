<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3 class="sub-title">Categorías</h3>
                    <ul class="support-link">
                        <li *ngFor="let category of categories">
                            <a (click)="goCourses(category)" class="pointer">{{
                                category?.name
                            }}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3 class="sub-title">Explorar</h3>
                    <ul class="support-link">
                        <li>
                            <a target="_blank" (click)="goStudent()"
                                >Plataforma U-Learning</a
                            >
                        </li>
                        <li>
                            <a target="_blank" [href]="campusUrl"
                                >Aula U-Learning</a
                            >
                        </li>
                        <li>
                            <a target="_blank" [href]="intranetUrl"
                                >Intranet U-Learning</a
                            >
                        </li>
                        <li>
                            <a target="_blank" href="https://www.netacad.com/"
                                >Plataforma CISCO</a
                            >
                        </li>
                        <li>
                            <a target="_blank" href="https://altissia.org/"
                                >Plataforma Altissia</a
                            >
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3 class="sub-title">Información Legal</h3>
                    <ul class="support-link">
                        <li>
                            <a target="_blank" href="https://logouss.s3.us-east-2.amazonaws.com/files/Terminos_y_condiciones_de_uso.pdf">Términos y condiciones</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://logouss.s3.us-east-2.amazonaws.com/files/Politicas_de_privacidad.pdf">Políticas de privacidad</a>
                        </li>
                        <li>
                            <a target="_blank"
                                href="https://campus.uss.edu.pe/96_LibRek/login.aspx?ReturnUrl=%2f96_LibRek%2fdefault.aspx"
                                >Libro de reclamaciones</a
                            >
                        </li>
                        <li>
                            <a target="_blank"
                                href="https://campus.uss.edu.pe/96_LibRek/Login.aspx?ReturnUrl=%2f96_LibRek%2ffrmResLibRecQuejas.aspx"
                                >Ver estado de su queja o reclamo</a
                            >
                        </li>
                        <li>
                            <a target="_blank"
                                href="https://campus.uss.edu.pe/75_FactView/Forms/DocumentoElectronico.aspx"
                                >Comprobantes electrónicos</a
                            >
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3 class="sub-title">Contáctanos</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class="bx bx-phone-call"></i>
                            <a href="tel:+51986728883">(+51) 986 728 883</a>
                        </li>
                        <li>
                            <i class="bx bx-envelope"></i>
                            <a href="mailto:portalulearning@crece.uss.edu.pe"
                                >portalulearning@crece.uss.edu.pe</a
                            >
                        </li>
                        <li>
                            <i class="bx bx-check-shield"></i>
                            <a target="_blank" [href]="certificationsUrl">Validación de Constancias y Certificados.</a>
                        </li>
                    </ul>
                    <!-- <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"
                    ><img src="assets/img/logo.png" alt="image"
                /></a>
            </div>
            <p>
                <i class="bx bx-copyright"></i>2021 U-Learning | Todos los
                derechos reservados.
            </p>
            <div class="article-footer">
                <div class="article-share">
                    <ul class="social">
                        <li>
                            <a
                                href="https://www.facebook.com/ussipan"
                                class="facebook"
                                target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/ussipan/"
                                class="instagram"
                                target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.youtube.com/c/ussipan"
                                class="youtube"
                                target="_blank"
                                ><i class="bx bxl-youtube"></i
                            ></a>
                        </li>
                        <li>
                            <a href="https://wa.me/986728883" target="_blank"
                                ><i class="bx bxl-whatsapp"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
