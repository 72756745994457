import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "modality",
})
export class ModalityPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value) {
            return "";
        }

        value = value.toLowerCase();

        switch (value) {
            case "virtual":
                return "Virtual";
            case "onsite":
                return "En sitio";
            case "blend":
                return "Semipresencial";
            case "recorded":
                return "Grabado";
            default:
                return "";
        }
    }
}
