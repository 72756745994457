import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Category } from "src/app/models/category.model";
import { Course } from "src/app/models/course.model";

@Component({
    selector: "app-course-card",
    templateUrl: "./course-card.component.html",
    styleUrls: ["./course-card.component.scss"],
})
export class CourseCardComponent implements OnInit {
    constructor(private router: Router) {}

    @Input() course: Course;

    ngOnInit(): void {}

    getPrice(course: Course): number {
        if (!this.course || (this.course && this.course.is_free)) {
            return 0;
        }

        if (this.hasCoupon) {
            if (this.course.coupon.mode === "percentage") {
                const discount = Math.min(this.course.coupon.value, 100);
                return (this.course.amount * (100 - discount)) / 100;
            }
            const result = this.course.amount - this.course.coupon.value;
            return result > 0 ? result : 0;
        }

        return this.course.amount;
    }

    get priceBeforeCoupon() {
        if (!this.hasCoupon) {
            return 0;
        }

        return this.course.amount;
    }

    get hasCoupon() {
        return this.course.coupon ? true : false;
    }

    getImage(course: Course): string {
        if (course.main_image) {
            let original_url = course.main_image.original_url;
            if (
                original_url.indexOf("http://") !== 0 &&
                original_url.indexOf("https://") !== 0
            ) {
                original_url = `http://${original_url}`;
            }
            return original_url;
        }

        return `assets/img/courses/courses1.jpg`;
    }

    goCourses(category: Category) {
        this.router.navigate(["/courses"], {
            queryParams: { category_id: category.id },
        });
    }
}
