import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { User } from "../models/user.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, catchError, retry } from "rxjs/operators";
import { throwError } from "rxjs";
import { StoreService } from "./store.service";
import { AlertService } from "./alert.service";
import { Country } from "../models/country.model";

@Injectable({
    providedIn: "root",
})
export class UserService {
    constructor(
        private http: HttpClient,
        private storeService: StoreService,
        private alertService: AlertService
    ) {}

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getTokenBearer()}`,
        }),
    };

    login(
        username: string,
        password: string
    ): Observable<{ expired_at: string; token: string }> {
        let url = `${environment.apiUrl}/login`;

        return this.http
            .post<any>(url, {
                email: username,
                password,
            })
            .pipe(
                catchError((error) => {
                    if (error.status === 401) {
                        this.alertService.showAlert(
                            this.alertService.typeError,
                            this.alertService.loginError
                        );
                    }

                    return throwError(error);
                })
            );
    }

    forgotPassword(email: string): Observable<{ any }> {
        let url = `${environment.apiUrl}/forgot-password`;

        return this.http
            .post<any>(url, {
                email,
                callback: "ecommerce",
            })
            .pipe(
                catchError((error) => {
                    if (error.status === 401) {
                        this.alertService.showAlert(
                            this.alertService.typeError,
                            this.alertService.loginError
                        );
                    }

                    return throwError(error);
                })
            );
    }

    update(
        date_of_birth: Date,
        email: string,
        first_name: string,
        last_name: string,
        second_last_name: string,
        document_number: string,
        document_type: string,
        address: string,
        phone: string,
        country_id: number,
        gender: string
    ): Observable<{ data: any }> {
        let url = `${environment.apiUrl}/self-update`;

        return this.http
            .post<any>(
                url,
                {
                    date_of_birth,
                    email,
                    first_name,
                    last_name,
                    second_last_name,
                    document_number,
                    document_type,
                    address,
                    phone,
                    country_id,
                    gender,
                },
                this.httpOptions
            )
            .pipe(
                catchError((error) => {
                    console.log(error);
                    this.alertService.showErrors(error);
                    return throwError(error);
                })
            );
    }

    register(
        date_of_birth: Date,
        email: string,
        password: string,
        password_confirmation: string,
        first_name: string,
        last_name: string,
        second_last_name: string
    ): Observable<{ expired_at: string; token: string }> {
        let url = `${environment.apiUrl}/self-register`;

        return this.http
            .post<any>(url, {
                date_of_birth,
                email,
                password,
                password_confirmation,
                first_name,
                last_name,
                second_last_name,
            })
            .pipe(
                catchError((error) => {
                    this.alertService.showErrors(error);
                    return throwError(error);
                })
            );
    }

    logout() {
        this.storeService.logout();
    }

    setTokenBearer(token: string) {
        this.storeService.setTokenBearer(token);
    }

    getTokenBearer() {
        return this.storeService.getTokenBearer();
    }

    getProfile(token: string = ""): Observable<{ data: any }> {
        let url = `${environment.apiUrl}/profile?includes=plan`;
        if (token) {
            this.httpOptions = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            };
        }

        return this.http.get<any>(url, this.httpOptions);
    }

    storeAvatar(avatar: string): Observable<{ data: any }> {
        let url = `${environment.apiUrl}/users/avatar`;

        return this.http.post<any>(
            url,
            {
                avatar,
            },
            this.httpOptions
        );
    }

    setUser(data: any) {
        this.storeService.setUser(data);
    }

    getUser() {
        let user = this.storeService.getUser();
        if (user) {
            return new User(user);
        }
        return null;
    }

    selfResetPassword(
        email: string,
        password: string,
        password_confirmation: string,
        token: string
    ): Observable<any> {
        let url = `${environment.apiUrl}/self-reset-password`;

        return this.http
            .post<any>(
                url,
                {
                    email,
                    password,
                    password_confirmation,
                    token,
                },
                this.httpOptions
            )
            .pipe(
                catchError((error) => {
                    this.alertService.showErrors(error);
                    return throwError(error);
                })
            );
    }

    reset_my_password(
        password: string,
        password_confirmation: string
    ): Observable<string> {
        let url = `${environment.apiUrl}/reset-my-password`;

        return this.http
            .put<any>(
                url,
                {
                    password,
                    password_confirmation,
                },
                this.httpOptions
            )
            .pipe(
                catchError((error) => {
                    this.alertService.showErrors(error);
                    return throwError(error);
                })
            );
    }

    getCountries(): Observable<{
        data: Country[];
    }> {
        let url = `${environment.apiUrl}/countries`;

        return this.http.get<any>(url);
    }

    generate_auth_token(): Observable<{ data: { token } }> {
        let url = `${environment.apiUrl}/self-auth-token`;

        return this.http.post<any>(url, {}, this.httpOptions);
    }

    loginWithGoogle(
        data: any
    ): Observable<{ expired_at: string; token: string }> {
        let url = `${environment.apiUrl}/login-google`;

        return this.http.post<any>(url, data).pipe(
            catchError((error) => {
                if (error.status === 422) {
                    let errors: string[] = Object.values(error.error.errors);

                    errors.forEach((item: string) => {
                        this.alertService.showAlert(
                            this.alertService.typeError,
                            item
                        );
                    });
                }

                return throwError(error);
            })
        );
    }

    loginWithFacebook(
        data: any
    ): Observable<{ expired_at: string; token: string }> {
        let url = `${environment.apiUrl}/login-facebook`;

        return this.http.post<any>(url, data).pipe(
            catchError((error) => {
                if (error.status === 422) {
                    let errors: string[] = Object.values(error.error.errors);

                    errors.forEach((item: string) => {
                        this.alertService.showAlert(
                            this.alertService.typeError,
                            item
                        );
                    });
                }

                return throwError(error);
            })
        );
    }
}
