import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OwlOptions } from "ngx-owl-carousel-o";
import { Category } from "../../../models/category.model";

@Component({
    selector: "app-categories-style-one",
    templateUrl: "./categories-style-one.component.html",
    styleUrls: ["./categories-style-one.component.scss"],
})
export class CategoriesStyleOneComponent implements OnInit {
    @Input() categories: Category[] = [];

    public url;

    constructor(private router: Router) {}

    ngOnInit(): void {}

    categoriesSlides: OwlOptions = {
        loop: false,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
    };

    changeBackground(name) {
        if (name == "green") {
            return { "background-color": "#00BA5A" };
        } else if (name == "blue") {
            return { "background-color": "#2D2D8A" };
        } else {
            return { "background-color": "#444444" };
        }
    }

    goCourses(category: Category) {
        this.router.navigate(["/courses"], {
            queryParams: { category_id: category.id },
        });
    }


    getImage(category: Category): string {
        if (category.card_image) {
            let original_url = category.card_image.original_url;
            if (
                original_url.indexOf("http://") !== 0 &&
                original_url.indexOf("https://") !== 0
            ) {
                original_url = `http://${original_url}`;
            }
            return original_url;
        }

        return `assets/img/categories/icon_tech.png`;
    }
}
