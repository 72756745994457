import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'certificate'
})
export class IsCertificatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value){
        return "Gratis";
    }
    return "Con pago";
  }

}
