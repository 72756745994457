<app-header-style-one></app-header-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="">Inicio</a></li>
            </ul>
            <h2>Eventos</h2>
        </div>
    </div>
</div>
<div class="funfacts-area pt-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Año</label>
                    <div class="select-box">
                        <select class="form-select" (change)="handleYear($event)">
                            <option *ngFor="let year of years">{{ year?.date }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="funfacts-inner">
            <app-funfacts [items]="items" (selectMonthEvent)="selectMonthEvent($event)"></app-funfacts>
        </div>
    </div>
</div>
<div class="events-area pt-100 pb-70">
    <div class="container">
        <div class="single-events-box mb-30" *ngFor="let event of events">
            <app-event-large-card [event]="event"></app-event-large-card>
        </div>
        <h5 *ngIf="!existEvents()">Sin eventos a mostrar</h5>
        <div class="pagination-area text-center" *ngIf="existEvents()">
            <a *ngIf="paginator.currentPage > 1" class="next page-numbers"
                (click)="changePage(paginator.currentPage -1)"><i class='bx bx-chevron-left'></i></a>
            <a *ngIf="paginator.currentPage > 1" class="page-numbers" (click)="changePage(paginator.currentPage -1)">{{
                paginator.currentPage -1 }}</a>
            <span class="page-numbers current" aria-current="page">{{ paginator.currentPage }}</span>
            <a *ngIf="paginator.currentPage < paginator.lastPage" class="page-numbers"
                (click)="changePage(paginator.currentPage+1)">{{ paginator.currentPage
                +1 }}</a>
            <a *ngIf="paginator.currentPage < paginator.lastPage" class="next page-numbers"
                (click)="changePage(paginator.currentPage+1)"><i class='bx bx-chevron-right'></i></a>
        </div>
    </div>
</div>
