import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PaginatorService } from "./paginator.service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Partner } from '../models/partner.model';

@Injectable({
    providedIn: "root",
})
export class PartnerService {
    constructor(
        private http: HttpClient,
    ) {}

    getPartners(
        params: {} = {is_shop: true}
    ): Observable<{
        data: Partner[];
    }> {
        let url = `${environment.apiUrl}/partners`;

        return this.http.get<any>(url, { params });
    }
}
