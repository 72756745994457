import { Injectable } from "@angular/core";
import { ToastConfig, Toaster, ToastType } from "ngx-toast-notifications";

@Injectable({
    providedIn: "root",
})
export class AlertService {
    constructor(private toaster: Toaster) {}

    public typeSuccess: ToastType = "success";
    public typeError: ToastType = "danger";
    public typeWarning: ToastType = "warning";
    public typeInfo: ToastType = "info";
    public typePrimary: ToastType = "primary";
    public typeSecondary: ToastType = "secondary";
    public typeDark: ToastType = "dark";
    public typeLight: ToastType = "light";

    private types: Array<ToastType> = [
        this.typeSuccess,
        this.typeError,
        this.typeWarning,
        this.typeInfo,
        this.typePrimary,
        this.typeSecondary,
        this.typeDark,
        this.typeLight,
    ];

    /**
     * Purches
     */
    public purchaseNotSelectedGroup: string =
        "Seleccione un horario para completar el registro";
    public purchasePreviouslyAdded: string =
        "El elemento ya se encuentra en el carrito";
    public purchaseSuccessfullyAdded: string = "Elemento agregado al carrito";

    public purchaseDataUserRequired: string =
        "Por favor, completa los datos de tu perfil";
    public purchaseNeedBeforeRegister: string =
        "Es necesario registrarse para continuar con tu compra";

    /**
     * Profile
     */
    public profileUpdateSuccessfully: string = "Perfil actualizado";
    public profileUpdateError: string = "No logramos actualizar el perfil";

    /**
     * Password
     */
    public passwordUpdateError: string = "Algo salió mal, vuelva  intentarlo";
    public passwordUpdateSuccessfully: string = "Contraseña cambiada con éxito";
    public passwordForgotSuccessfully: string = "Revise su correo para restaurar la contraseña";
    public passwordChangeSuccessfully: string = "Contraseña cambiada, ingrese con sus nuevas credenciales";

    /**
     * Login
     */
    public loginError: string = "Las credenciales no son correctas";
    public suspendedError: string = "Usuario suspendido, no puede iniciar sesión";

    public messageError: string = "Ha ocurrido un error, intente nuevamente";

    /**
     * Register
     */
    public registerSuccessfully: string = "Registro exitoso";

    /**
     *
     * @param type
     * @param text
     * @returns
     */

    popupCenter(url: string, title: string, w: number, h: number) {
        // Fixes dual-screen position                             Most browsers      Firefox
        let dualScreenLeft =
            window.screenLeft !== undefined
                ? window.screenLeft
                : window.screenX;
        let dualScreenTop =
            window.screenTop !== undefined ? window.screenTop : window.screenY;

        let width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : screen.width;
        let height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : screen.height;

        let systemZoom = width / window.screen.availWidth;
        let left = (width - w) / 2 / systemZoom + dualScreenLeft;
        let top = (height - h) / 2 / systemZoom + dualScreenTop;
        let newWindow = window.open(
            url,
            title,
            `
          scrollbars=yes,
          width=${w / systemZoom},
          height=${h / systemZoom},
          top=${top},
          left=${left}
          `
        );
    }

    showAlert(type: ToastType, text: string) {
        if (!this.validateType(type)) {
            return;
        }
        this.toaster.open({
            text: text,
            // caption: type + " notification",
            type: type,
            position: "top-center",
        });
    }

    showErrors(error: any) {
        if (error.status == 403 || error.status == 409) {
            this.showAlert(this.typeError, error.error.message);
            return;
        }

        if (error.status !== 422) {
            return;
        }

        if (error.error && error.error.errors) {
            let message = ``;
            Object.values(error.error.errors).forEach((element) => {
                message += `${element[0]} \n`;
            });

            this.showAlert(this.typeError, message);
            return;
        }

        if (error.error && !error.error.errors && error.error.message) {
            this.showAlert(this.typeError, error.error.message);
            return;
        }
    }

    validateType(type: ToastType) {
        return this.types.includes(type);
    }
}
