import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UserService } from '../../../services/user.service';

@Component({
    selector: "app-profile-picture-page",
    templateUrl: "./profile-picture-page.component.html",
    styleUrls: ["./profile-picture-page.component.scss"],
})
export class ProfilePicturePageComponent implements OnInit {
    // form: FormGroup;
    submitted = false;

    constructor(
        private userService: UserService // private formBuilder: FormBuilder,
    ) {}

    ngOnInit(): void {}

    name = "Angular ";
    dataimage: any;

    @ViewChild("fileInput") fileInput: ElementRef;
    fileAttr = "Choose File";

    uploadFileEvt(imgFile: any) {
        if (imgFile.target.files && imgFile.target.files[0]) {
            this.fileAttr = "";
            Array.from(imgFile.target.files).forEach((file: File) => {
                this.fileAttr += file.name;
            });

            // HTML5 FileReader API
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let image = new Image();
                image.src = e.target.result;
                image.onload = (rs) => {
                    let imgBase64Path = e.target.result;
                    this.dataimage = imgBase64Path;
                    this.storeAvatar(imgBase64Path);
                };
            };
            reader.readAsDataURL(imgFile.target.files[0]);

            // Reset if duplicate image uploaded again
            this.fileInput.nativeElement.value = "";
        } else {
            this.fileAttr = "Choose File";
        }
    }

    storeAvatar(avatar: string) {
        this.userService.storeAvatar(avatar).subscribe((response) => {
            console.log(response)
        })
    }
}
