import { Component, ElementRef, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { CourseService } from "src/app/services/course.service";
import { Course } from "../../../models/course.model";
import { CategoryService } from "../../../services/category.service";
import { Category } from "../../../models/category.model";
import { Paginator } from "../../../models/paginator.model";
import { filter } from "rxjs/operators";

@Component({
    selector: "app-courses-list-page",
    templateUrl: "./courses-list-page.component.html",
    styleUrls: ["./courses-list-page.component.scss"],
})
export class CoursesListPageComponent implements OnInit {
    subscriptions: Subscription[] = [];
    public courses: Course[] = [];
    public categories: {
        name: string;
        value: string | number;
        active: boolean;
    }[] = [
        {
            name: "Todos",
            value: "",
            active: true,
        },
    ];
    public category: Category | null = null;
    public category_id: number | null = null;
    public paginator: Paginator = Paginator.getDefault();
    private timeout: any;
    public is_all_category: boolean = false;

    public languages: {
        name: string;
        value: string | number;
        active: boolean;
    }[] = [];

    public modalities: {
        name: string;
        value: string | number;
        active: boolean;
    }[] = [];

    public origins: {
        name: string;
        value: string | number;
        active: boolean;
    }[] = [];

    public natures: {
        name: string;
        value: string | number;
        active: boolean;
    }[] = [];

    private queryParams: {} = {};
    private selectedLanguages: any[] = [];
    private selectedCategories: any[] = [];
    public isFree: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private courseService: CourseService,
        private categoryService: CategoryService,
        private element: ElementRef
    ) {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.paginator = Paginator.getDefault();
            }
        });
    }

    ngOnInit(): void {
        this.getCategories();

        this.route.queryParams.subscribe((params) => {
            this.init();
            if (params) {
                this.queryParams = params;

                if (params["category_id"]) {
                    this.selectedCategories = params["category_id"].split(",");
                }

                if (params["language_id"]) {
                    this.selectedLanguages = params["language_id"].split(",");
                }

                if (params["modality"]) {
                    let arrayModality = params["modality"].split(",");

                    this.modalities.forEach((item) => {
                        arrayModality.forEach((element) => {
                            if (item.value === element) {
                                item.active = true;
                            }
                        });
                    });
                }

                if (params["origin"]) {
                    let arrayOrigin = params["origin"].split(",");

                    this.origins.forEach((item) => {
                        arrayOrigin.forEach((element) => {
                            if (item.value === element) {
                                item.active = true;
                            }
                        });
                    });
                }

                if (params["nature"]) {
                    let arrayNature = params["nature"].split(",");

                    this.natures.forEach((item) => {
                        arrayNature.forEach((element) => {
                            if (item.value === element) {
                                item.active = true;
                            }
                        });
                    });
                }

                if (params["is_free"]) {
                    if (params["is_free"]) {
                        this.isFree = true;
                    } else {
                        this.isFree = false;
                    }
                }
                this.getCourses();
            }
        });
    }

    init() {
        this.natures = [
            {
                name: "De autoaprendizaje",
                value: "self_learning",
                active: false,
            },
            {
                name: "Con tutor online",
                value: "online",
                active: false,
            },
            {
                name: "Con docente",
                value: "teacher",
                active: false,
            },
        ];
        this.origins = [
            {
                name: "En plataforma",
                value: "platform",
                active: false,
            },
            {
                name: "En LMS",
                value: "lms",
                active: false,
            },
        ];
        this.modalities = [
            {
                name: "Virtual",
                value: "virtual",
                active: false,
            },
            {
                name: "Presencial",
                value: "onsite",
                active: false,
            },
            {
                name: "Semipresencial",
                value: "blend",
                active: false,
            },
            {
                name: "Grabada",
                value: "recorded",
                active: false,
            },
        ];
    }

    getCourses(category_id?: number, search?: string): void {
        let params = {};
        if (search) {
            params = {
                title: search,
            };
        }
        params = {
            ...params,
            ...this.queryParams,
        };
        this.subscriptions.push(
            this.courseService
                .getCourses(this.paginator, params)
                .subscribe((response) => {
                    this.courses = response.data;
                    this.paginator = new Paginator(response.meta);
                })
        );
    }

    handleClearFilters() {
        this.categories.forEach((item) => {
            item.active = false;
        });
        this.languages.forEach((item) => {
            item.active = false;
        });
        this.isFree = false;

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
        });
    }

    handleFilter(queryParams: Params): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: "merge", // remove to replace all query params by provided
        });
    }

    handleLanguages(filter: any): void {
        let params = [];
        this.languages.forEach((item) => {
            if (item.value === filter.value) {
                item.active = !item.active;
            }

            if (item.active) {
                params.push(item.value);
            }
        });
        const queryParams: Params = { language_id: params.join(",") };
        this.handleFilter(queryParams);
    }

    handleCategory(filter: any): void {
        let params = [];
        this.is_all_category = false;

        this.categories.forEach((item) => {
            if (item.value === filter.value) {
                item.active = !item.active;
            }

            if (item.active) {
                params.push(item.value);
            }

            if (item.value === "" && item.active) {
                this.is_all_category = true;
            } else if (filter.value !== "") {
                this.is_all_category = false;
            }
        });

        this.categories.forEach((item) => {
            if (this.is_all_category) {
                if (item.value !== "") {
                    item.active = false;
                } else {
                    item.active = true;
                }
            } else {
                if (item.value === "" && item.active) {
                    item.active = false;
                }
            }
        });
        const queryParams: Params = { category_id: params.join(",") };
        this.handleFilter(queryParams);
    }

    handleModality(filter: any): void {
        let params = [];
        this.modalities.forEach((item) => {
            if (item.value === filter.value) {
                item.active = !item.active;
            }

            if (item.active) {
                params.push(item.value);
            }
        });
        const queryParams: Params = { modality: params.join(",") };
        this.handleFilter(queryParams);
    }

    handleOrigin(filter: any): void {
        let params = [];
        this.origins.forEach((item) => {
            if (item.value === filter.value) {
                item.active = !item.active;
            }

            if (item.active) {
                params.push(item.value);
            }
        });
        const queryParams: Params = { origin: params.join(",") };
        this.handleFilter(queryParams);
    }

    handleNature(filter: any): void {
        let params = [];
        this.natures.forEach((item) => {
            if (item.value === filter.value) {
                item.active = !item.active;
            }

            if (item.active) {
                params.push(item.value);
            }
        });
        const queryParams: Params = { nature: params.join(",") };
        this.handleFilter(queryParams);
    }

    handleFree(): void {
        this.isFree = !this.isFree;
        let isFree;
        if (this.isFree) {
            isFree = true;
        } else {
            isFree = "";
        }

        const queryParams: Params = { is_free: isFree };
        this.handleFilter(queryParams);
    }

    ngOnDestroy() {
        this.category = null;
        this.category_id = null;
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    getCategories() {
        let params: {} = { type: "course,language", parents: 1 };
        this.categoryService.getCategories(params).subscribe((response) => {
            let data = response.data;
            for (let index = 0; index < data.length; index++) {
                const item = data[index];
                if (item.type === "course") {
                    this.categories.push({
                        name: item.name,
                        value: item.id,
                        active: false,
                    });
                }
                if (item.type === "language") {
                    this.languages.push({
                        name: item.name,
                        value: item.id,
                        active: false,
                    });
                }
            }

            this.categories.forEach((item) => {
                this.selectedCategories.forEach((element) => {
                    if (item.value == element) {
                        item.active = true;
                    }
                });
            });

            this.languages.forEach((item) => {
                this.selectedLanguages.forEach((element) => {
                    if (item.value == element) {
                        item.active = true;
                    }
                });
            });
        });
    }

    changePage(page) {
        this.paginator.page = page;
        this.getCourses(this.category_id);
        // this.element.nativeElement.scrollIntoView();
    }

    onKeyDown(event) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            if (event.key !== "Enter") {
                clearTimeout(this.timeout);
            }
            this.getCourses(this.category_id, event.target.value);
            // this.element.nativeElement.scrollIntoView();
        }, 1000);
    }
}
