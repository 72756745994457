<div class="events-box">
    <div class="events-image">
        <div class="image">
            <img src="{{getImage(event)}}" alt="image">
        </div>
    </div>
    <div class="events-content">
        <div class="content">
            <h3><a [routerLink]="['/events-detail/', event.id]">{{ event.title }}</a></h3>
            <div [innerHTML]="event?.description_short"></div>
            <!-- <span class="location"><i class='bx bx-map'></i> {{ event.location }}</span> -->
            <a [routerLink]="['/events-detail/', event.id]" class="join-now-btn">Ver más</a>
        </div>
    </div>
    <div class="events-date">
        <div class="date">
            <div class="d-table">
                <div class="d-table-cell">
                    <h3>{{ event.date | date: 'dd/MM/yyyy' }}</h3>
                    <p>{{ event.date | date: 'h:mm a' }}</p>
                    <i class='bx bx-calendar'></i>
                </div>
            </div>
        </div>
    </div>
</div>
