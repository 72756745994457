<!-- <app-header-style-two></app-header-style-two> -->
<app-header-style-one></app-header-style-one>
<div class="body-timer">
    <div class="timer"><i>Actualizando en ... {{ time }}</i></div>
</div>
<div class="cart-area ptb-70">
    <div class="container">
        <form>
            <div class="cart-table table-responsive" *ngIf="payments.length > 0">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Curso</th>
                            <th scope="col">Horario</th>
                            <th scope="col">Precio</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let payment of payments">
                            <tr *ngFor="let item of payment.items">
                                <td class="product-name">
                                    <a [routerLink]="['/courses/', item?.course?.slug]">{{ item?.title }}</a>
                                </td>
                                <td class="product-price">
                                    <span class="unit-amount">{{ item?.variant?.group?.name }}</span>
                                </td>
                                <td class="product-price">
                                    <span class="unit-amount">{{ getPrice(item) | currency }}</span>
                                </td>

                                <td class="product-subtotal">
                                    <button *ngIf="!payment?.payment_code" (click)="generateInscription(payment)"
                                        class="default-btn inscription-btn"><i
                                            class='bx bx-shopping-bag icon-arrow before'></i><span
                                            class="label inscription-label">Procesar pago</span><i
                                            class="bx bx-shopping-bag icon-arrow after"></i></button>
                                    <button *ngIf="payment?.payment_code" class="default-btn inscription-btn pending"
                                        (click)="availableCheckout(payment,payment?.payment_code)">Ir a
                                        pagar</button>
                                    <a (click)="removeItem(payment)" class="remove"><i class='bx bx-trash'></i></a>
                                </td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>
            </div>
            <h5 *ngIf="payments.length === 0">No tiene elementos en el carrito</h5>

            <!-- <div class="cart-totals">
                <h3>Total en el carrito</h3>
                <ul>
                    <li>Total <span>{{ total | currency }}</span></li>
                </ul>
                <a (click)="generatePayment()" class="default-btn"><i
                        class='bx bx-shopping-bag icon-arrow before'></i><span class="label">Procesar pago</span><i
                        class="bx bx-shopping-bag icon-arrow after"></i></a>
            </div> -->
        </form>
    </div>
</div>
