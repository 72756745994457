import { Component, Input, OnInit } from "@angular/core";
import { Category } from "src/app/models/category.model";
import { Course } from "src/app/models/course.model";

@Component({
    selector: "app-homeone-courses",
    templateUrl: "./homeone-courses.component.html",
    styleUrls: ["./homeone-courses.component.scss"],
})
export class HomeoneCoursesComponent implements OnInit {
    @Input() courses: Course[] = [];
    @Input() categories: Category[] = [];
    @Input() tabs: { category_name: string; courses: any[] }[] = [];

    ngOnInit(): void {}
}
