<!-- <app-preloader></app-preloader> -->
<div
  class="modal cookies-modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <p>Utilizamos cookies propias y de terceros para mejorar nuestros servicios y mostrarle publicidad relacionada con sus preferencias mediante el análisis de sus hábitos de navegación. Si continua navegando, consideramos que acepta su uso. Puede cambiar la configuración. <a target="_blank" href="https://logouss.s3.us-east-2.amazonaws.com/files/Politicas_de_privacidad.pdf">Leer más.</a></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger"
        (click)="declineCookies()">
        Rechazar
        </button>
        <button type="button" class="btn btn-success"
                (click)="acceptCookies()">
          Aceptar
        </button>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
<app-footer *ngIf="!(location == '/login' || location == '/register' || location == '/coming-soon')"></app-footer>
