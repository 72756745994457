import { Component, OnInit, Input } from "@angular/core";
import { Course } from "src/app/models/course.model";
import { Group } from "src/app/models/group.model";
import { AlertService } from "src/app/services/alert.service";
import { StoreService } from "../../../services/store.service";
import { environment } from "../../../../environments/environment.prod";
import { PaymentService } from "../../../services/payment.service";
import { SubscriptionService } from "../../../services/subscription.service";
import { User } from "src/app/models/user.model";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";

@Component({
    selector: "app-course-summary",
    templateUrl: "./course-summary.component.html",
    styleUrls: ["./course-summary.component.scss"],
})
export class CourseSummaryComponent implements OnInit {
    private user: User | null = null;

    constructor(
        private paymentService: PaymentService,
        private subscriptionService: SubscriptionService,
        private alertService: AlertService,
        private storeService: StoreService,
        private userService: UserService,
        private router: Router
    ) {}

    @Input() course: Course;
    @Input() group: Group | null = null;
    @Input() groups: Group[] = [];

    ngOnInit(): void {
        this.user = this.storeService.getUser();
    }

    get hasGroups() {
        return this.groups?.length > 0;
    }

    addToCart() {
        if (this.course.is_free || this.canTakeWithPlan) {
            this.selfSubscription();
            return;
        }
        this.paymentService.addToCart(this.course, this.group);
    }

    get canTakeWithPlan() {
        return this.course?.access_with_plan && this.user?.plan?.is_active;
    }

    getUrl() {
        return `${environment.baseUrl}/courses/${this.course.slug}`;
    }

    getImage(): string {
        if (this.course && this.course.main_image) {
            let original_url = this.course.main_image.original_url;
            if (
                original_url.indexOf("http://") !== 0 &&
                original_url.indexOf("https://") !== 0
            ) {
                original_url = `http://${original_url}`;
            }
            return original_url;
        }

        return `assets/img/avatar.jpg`;
    }

    get price(): number {
        if (!this.course || (this.course && this.course.is_free)) {
            return 0;
        }

        if (this.hasCoupon) {
            if (this.course.coupon.mode === "percentage") {
                const discount = Math.min(this.course.coupon.value, 100);
                return (this.course.amount * (100 - discount)) / 100;
            }
            const result = this.course.amount - this.course.coupon.value;
            return result > 0 ? result : 0;
        }

        return this.course.amount;
    }

    get priceBeforeCoupon() {
        if (!this.hasCoupon) {
            return 0;
        }

        return this.course.amount;
    }

    get hasCoupon() {
        return this.course?.coupon ? true : false;
    }

    get hasQuotas() {
        return this.course?.default_billing ? true : false;
    }

    get quotas() {
        if (!this.hasQuotas || this.canTakeWithPlan) {
            return;
        }

        return `Con ${this.defaultBilling.number_of_payments} cuotas de S/ ${this.defaultBilling.amount}`;
    }

    get defaultBilling() {
        return this.course?.default_billing;
    }

    buyButton() {
        if (this.course && (this.course.is_free || this.canTakeWithPlan)) {
            return "Inscribirme";
        }
        return "Comprar ahora";
    }

    selfSubscription() {
        if (this.user == null) {
            this.alertService.showAlert(
                this.alertService.typeInfo,
                this.alertService.purchaseDataUserRequired
            );
            this.router.navigate(["/login"]);
            return;
        }

        if (
            this.user.phone === null ||
            this.user.documentNumber === null ||
            this.user.country_id === null ||
            this.user.first_name === null ||
            this.user.last_name === null ||
            this.user.second_last_name === null
        ) {
            this.alertService.showAlert(
                this.alertService.typeWarning,
                this.alertService.purchaseDataUserRequired
            );
            this.router.navigate(["/profile"]);
            return;
        }

        if (this.course.modality !== "recorded" && !this.group) {
            this.alertService.showAlert(
                this.alertService.typeInfo,
                this.alertService.purchaseNotSelectedGroup
            );
            return;
        }

        let data = {
            course_id: this.course.id,
            group_id: this.group.id,
        };

        this.subscriptionService.selfsubscription(data).subscribe(() => {
            this.course.is_purchased = true;
            this.generateAuthToken();
        });
    }

    generateAuthToken() {
        this.userService.generate_auth_token().subscribe((response) => {
            window.open(
                `${environment.studentUrl}/sessions/signin-token/${response.data.token}`,
                "_blank"
            );
        });
    }

    redirectToHome() {
        this.router.navigate(["/"], { state: { reload: true } });
    }
}
