<header class="header-area p-relative">
    <div class="top-header top-header-style-four" >
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-7">
                    <div class="top-header-social">
                        <span>Sigue conectado:</span>
                        <a
                            href="https://www.facebook.com/ussipan"
                            target="_blank"
                            ><i class="bx bxl-facebook"></i
                        ></a>
                        <a
                            href="https://www.instagram.com/ussipan/"
                            target="_blank"
                            ><i class="bx bxl-instagram"></i
                        ></a>
                        <a
                            href="https://www.youtube.com/c/ussipan"
                            target="_blank"
                            ><i class="bx bxl-youtube"></i
                        ></a>
                        <a href="https://wa.me/986728883" target="_blank"
                            ><i class="bx bxl-whatsapp"></i
                        ></a>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5">
                    <ul class="top-header-login-register">
                        <li class="nav-link-campus">
                            <button (click)="goIntranet()" target="_blank"  class="nav-link-aula btnIntranet">Intranet U-Learning </button>
                        </li>
                        <li *ngIf="!isLogin">
                            <a routerLink="/login"
                                ><i class="bx bx-log-in"></i> Ingresar</a
                            >
                        </li>
                        <li *ngIf="!isLogin">
                            <a routerLink="/register"
                                ><i class="bx bx-log-in-circle"></i> Registro</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div
        class="navbar-area navbar-style-three"
        ngStickyNav
        stickyClass="sticky-box-shadow"
        ngStickyNav
    >
        <div class="container">
            <nav
                class="navbar navbar-expand-lg navbar-light bg-light"
                [class.active]="classApplied"
            >
                <a class="navbar-brand" routerLink="/"
                    ><img src="assets/img/black-logo.png" alt="logo"
                /></a>
                <button
                    class="navbar-toggler"
                    type="button"
                    (click)="toggleClass()"
                >
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>

                <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav-areas">
                        <li class="nav-link-campus">
                            <button (click)="goStudent()" target="_blank"  class="anime nav-link-aula">Plataforma U-Learning</button>
                        </li>
                        <li class="nav-link-campus">
                            <button (click)="goCampus()" target="_blank"  class="anime nav-link-campus">Aula U-Learning</button>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <li class="others-option">
                            <div class="search-box d-inline-block">
                                <i
                                    class="bx bx-search"
                                    (click)="toggleSearch()"
                                ></i>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Inicio</a
                            >
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Cursos <i class="bx bx-chevron-down"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        [routerLink]="['/courses']"
                                        class="nav-link pointer"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Todos los cursos</a
                                    >

                                    <a
                                        (click)="goCourses(category)"
                                        class="nav-link pointer"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        *ngFor="let category of categories"
                                        >{{ category?.name }}</a
                                    >
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/events"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Eventos</a
                            >
                        </li>
                        <li class="nav-item">
                            <div class="others-option">
                                <app-shopping-cart></app-shopping-cart>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="others-option" *ngIf="isLogin">
                    <div
                        class="dropdown language-switcher d-inline-block"
                        [class.active]="classApplied2"
                    >
                        <button
                            class="dropdown-toggle"
                            type="button"
                            (click)="toggleClass2()"
                        >
                            <img [src]="user.avatarUrl" alt="flag" />
                        </button>
                        <div class="dropdown-menu">
                            <a
                                routerLink="/profile"
                                class="dropdown-item d-flex align-items-center"
                                >Mi perfil</a
                            >
                            <a
                                (click)="logout()"
                                class="dropdown-item d-flex align-items-center button-logout"
                                >Salir <i class="bx bx-log-in-circle"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleSearch()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input
                        type="text"
                        class="input-search"
                        placeholder="Buscar un curso"
                    />
                    <button type="submit">
                        <i class="bx bx-search-alt"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
