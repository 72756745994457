import { Component, Input, OnInit } from "@angular/core";
import { Blog } from "../../../models/blog.model";

@Component({
    selector: "app-event-large-card",
    templateUrl: "./event-large-card.component.html",
    styleUrls: ["./event-large-card.component.scss"],
})
export class EventLargeCardComponent implements OnInit {
    constructor() {}

    @Input() event: Blog;

    ngOnInit(): void {}

    getImage(blog: Blog): string {
        if (blog.main_image) {
            let original_url = blog.main_image.original_url;
            if (
                original_url.indexOf("http://") !== 0 &&
                original_url.indexOf("https://") !== 0
            ) {
                original_url = `http://${original_url}`;
            }
            return original_url;
        }

        return `assets/img/logo.png`;
    }
}
