<app-header-style-one></app-header-style-one>

<div class="my-dashboard-area">
    <div class="container">
        <app-profile-navigation></app-profile-navigation>
        <div class="myDashboard-content pb-70">
            <form class="edit-account" [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <legend>Cambiar contraseña</legend>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Nueva contraseña</label>
                            <input type="password" class="form-control"
                            formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                            <p *ngIf="f.password.invalid && f.password.touched" class="validation-error">Formato no válido</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Confirmar nueva contraseña</label>
                            <input type="password" class="form-control"
                            formControlName="password_confirmation" [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }">
                            <p *ngIf="f.password_confirmation.invalid && f.password_confirmation.touched" class="validation-error">Formato no válido</p>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn">
                            <i class='bx bx-save icon-arrow before'></i>
                            <span class="label">Guardar cambios</span>
                            <i class="bx bx-save icon-arrow after"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>