import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PaginatorService } from "./paginator.service";
import { Observable } from "rxjs";
import { Paginator, PaginatorResponse } from "../models/paginator.model";
import { Blog } from "../models/blog.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class BlogService {
    constructor(
        private http: HttpClient,
        private paginatorService: PaginatorService
    ) {}

    getBlogs(
        paginator: Paginator = Paginator.getDefault(),
        params: {}
    ): Observable<{
        data: Blog[];
        meta: PaginatorResponse;
    }> {
        let url = `${environment.apiUrl}/blogs`;
        params = this.paginatorService.transformParams(paginator, params);

        return this.http.get<any>(url, {
            params: params,
        });
    }

    findBlog(id: number): Observable<{
        data: Blog;
    }> {
        let url = `${environment.apiUrl}/blogs/${id}`;

        return this.http.get<any>(url, {});
    }

    getDates(): Observable<{
        data: { date: number }[];
    }> {
        let url = `${environment.apiUrl}/blogs-dates`;

        return this.http.get<any>(url, {});
    }
}
