<div class="single-courses-box mb-30">
    <div class="courses-image">
        <a [routerLink]="['/courses/', course.slug]" class="d-block">
            <img src="{{getImage(course)}}" alt="image">

        </a>
        <div class="courses-tag">
            <a (click)="goCourses(course.category)" class="d-block pointer"><b>{{ course?.category.name }}</b></a>
        </div>
    </div>
    <div class="courses-content">
        <h3><a [routerLink]="['/courses/', course.slug]" class="d-inline-block">{{course.title}}</a></h3>
        <div class="courses-rating">
            <div class="review-stars-rated">
                <div [outerHTML]="course.rating_average | rating"></div>
            </div>
            <div class="rating-total">
                ({{ course.rating_count }})
            </div>
        </div>
    </div>
    <div class="courses-box-footer">
        <ul>
            <li class="students-number">
                <i class='bx bx-user'></i> {{ course.students_count }} estudiantes
            </li>
            <li class="courses-lesson">
                <i class='bx bx-book-open'></i> {{ course.lessons_count }} sesiones
            </li>
            <li class="courses-price">
                {{ getPrice(course) | currency}} <small class="old-price text-muted" *ngIf="hasCoupon">({{
                    priceBeforeCoupon | currency }})</small>
            </li>
        </ul>
    </div>
</div>
