import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Category } from "../models/category.model";
import { Paginator, PaginatorResponse } from "../models/paginator.model";
import { PaginatorService } from "./paginator.service";

@Injectable({
    providedIn: "root",
})
export class CategoryService {
    constructor(
        private http: HttpClient,
        private paginatorService: PaginatorService
    ) {}

    getCategories(
        params: {}
    ): Observable<{
        data: Category[];
        meta: PaginatorResponse;
    }> {
        let url = `${environment.apiUrl}/categories`;

        return this.http.get<any>(url, {
            params: params,
        });
    }
}
