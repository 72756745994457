<app-header-style-one></app-header-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
            </ul>
            <h2>{{ !is_all_category ? category?.name : 'Todos los cursos' }}</h2>
        </div>
    </div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">
                <div class="col-lg-3">
                    <div class="topbar-result-count">
                        <button class="btn optional-btn" (click)="handleClearFilters()">X Limpiar filtros</button>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div class="col-lg-4">
                                <div class="topbar-ordering">
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="topbar-search">
                                    <form>
                                        <label><i class="bx bx-search"></i></label>
                                        <input (keydown)="onKeyDown($event)" type="text" class="input-search"
                                            placeholder="Buscar...">
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row list-container">
            <div class="col-lg-2 col-md-3 col-sm-3 sidebar-categories">
                <aside class="widget-area">
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Categorías</h3>
                        <div class="tagcloud">
                            <button [ngClass]="{ 'active' : item.active }" type="button" *ngFor="let item of categories"
                                (click)="handleCategory(item)">{{item.name}}</button>
                        </div>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Idiomas</h3>
                        <div class="tagcloud">
                            <button [ngClass]="{ 'active' : item.active }" type="button" *ngFor="let item of languages"
                                (click)="handleLanguages(item)">{{item.name}}</button>
                        </div>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Modalidad</h3>
                        <div class="tagcloud">
                            <button [ngClass]="{ 'active' : item.active }" type="button" *ngFor="let item of modalities"
                                (click)="handleModality(item)">{{item.name}}</button>
                        </div>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Origen</h3>
                        <div class="tagcloud">
                            <button [ngClass]="{ 'active' : item.active }" type="button" *ngFor="let item of origins"
                                (click)="handleOrigin(item)">{{item.name}}</button>
                        </div>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Naturaleza</h3>
                        <div class="tagcloud">
                            <button [ngClass]="{ 'active' : item.active }" type="button" *ngFor="let item of natures"
                                (click)="handleNature(item)">{{item.name}}</button>
                        </div>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Más</h3>
                        <div class="tagcloud">
                            <button [ngClass]="{ 'active' : isFree }" type="button"
                                (click)="handleFree()">Gratuitos</button>
                        </div>
                    </div>
                </aside>
            </div>
            <div class="col-lg-10 col-md-9 col-sm-9 list-courses">
                <div class="row">
                    <div class="col-lg-12 col-md-12" *ngFor="let course of courses">
                        <app-course-large-card [course]="course"></app-course-large-card>
                    </div>
                    <h5 *ngIf="courses.length === 0">No se encontraron resultados</h5>
                </div>

                <div class="pagination-area text-center" *ngIf="courses.length !== 0">
                    <a *ngIf="paginator.currentPage > 1" class="next page-numbers"
                        (click)="changePage(paginator.currentPage -1)"><i class='bx bx-chevron-left'></i></a>
                    <a *ngIf="paginator.currentPage > 1" class="page-numbers"
                        (click)="changePage(paginator.currentPage -1)">{{
                        paginator.currentPage -1 }}</a>
                    <span class="page-numbers current" aria-current="page">{{ paginator.currentPage }}</span>
                    <a *ngIf="paginator.currentPage < paginator.lastPage" class="page-numbers"
                        (click)="changePage(paginator.currentPage+1)">{{ paginator.currentPage
                        +1 }}</a>
                    <a *ngIf="paginator.currentPage < paginator.lastPage" class="next page-numbers"
                        (click)="changePage(paginator.currentPage+1)"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>