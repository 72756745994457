<div class="single-courses-list-box mb-30">
    <div class="box-item">
        <div class="courses-image">
            <div class="image">
                <img src="{{getImage(course)}}" alt="image">
                <a [routerLink]="['/courses/', course.slug]" class="link-btn"></a>
                <div class="courses-tag">
                    <a (click)="goCourses(course.category)" class="d-block pointer">{{ course?.category.name
                        }}</a>
                </div>
            </div>
        </div>
        <div class="courses-desc">
            <div class="courses-content">
                <h3><a [routerLink]="['/courses/', course.slug]" class="d-inline-block">{{course?.title}}</a></h3>
                <div class="courses-rating">
                    <div class="review-stars-rated">
                        <div [outerHTML]="course.rating_average | rating"></div>
                    </div>
                    <div class="rating-total">
                        ({{ course.rating_count }})
                    </div>
                </div>
                <p>{{ getDescription() }}</p>
            </div>
            <div class="courses-box-footer">
                <ul>
                    <li class="students-number">
                        <i class='bx bx-user'></i> {{ course.students_count }} estudiantes
                    </li>
                    <li class="courses-lesson">
                        <i class='bx bx-book-open'></i> {{ course.lessons_count }} sesiones
                    </li>
                    <li class="courses-price">
                        {{ getPrice(course) | currency}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>