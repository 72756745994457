<div class="myDashboard-navigation">
    <ul>
        <li>
            <a routerLink="/profile" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <i class='bx bx-home-alt'></i> Perfil</a>
        </li>
        <li>
            <a routerLink="/reset-password" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <i class='bx bx-edit'></i> Cambiar contraseña</a>
        </li>
    </ul>
</div>