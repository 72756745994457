import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "translate",
})
export class TranslatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        let response = "";
        switch (value) {
            case "monday":
                response = "Lunes";
                break;
            case "tuesday":
                response = "Martes";
                break;
            case "wednesday":
                response = "Miércoles";
                break;
            case "thursday":
                response = "Jueves";
                break;
            case "friday":
                response = "Viernes";
                break;
            case "saturday":
                response = "Sábado";
                break;
            case "sunday":
                response = "Domingo";
                break;
        }
        return response;
    }
}
