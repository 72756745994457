import { Component, OnInit } from "@angular/core";
import { Router, NavigationCancel, NavigationEnd } from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { filter } from "rxjs/operators";
import { UserService } from "./services/user.service";
declare let $: any;
import { Title } from "@angular/platform-browser";
import { AlertService } from "./services/alert.service";
declare const gtag: Function; // <------------Important: the declartion for gtag is required!
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent {
    location: any;
    routerSubscription: any;
    private ls = localStorage;

    constructor(
        private router: Router,
        private userService: UserService,
        private titleService: Title,
        private alertService: AlertService,
    ) {
        let analytics_active = false;
        if (this.cookiesAnalytics && this.cookiesAnalytics === 'accept') {
            analytics_active = true;
        }
        if (!this.cookiesAnalytics) {
            this.openPopup();
        }
        this.titleService.setTitle("U-Learning");
        /** START : Code to Track Page View using gtag.js */
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            let whiteList = ['/login', '/register','/forgot-password'];
            if ( !whiteList.includes(this.router.url)) {
                this.userService.getProfile().subscribe((response: any) => {
                  if (response.data.is_suspended) {
                    this.alertService.showAlert(
                        this.alertService.typeError,
                        this.alertService.suspendedError
                    );
                    this.userService.logout();

                    setTimeout(() => {
                        location.reload();
                    }, 2000)
                  }
                });
            }

            if (analytics_active) {
                gtag('event', 'page_view', {
                    page_path: event.urlAfterRedirects
                })
            }
        })
        /** END : Code to Track Page View  using gtag.js */
    }
    displayStyle = "none";

    openPopup() {
      this.displayStyle = "block";
    }

    closePopup() {
      this.displayStyle = "none";
    }

    acceptCookies() {
        this.ls.setItem("analitycs", "accept");
        this.closePopup();
    }

    declineCookies() {
        this.ls.setItem("analitycs", "decline");
        this.closePopup();
    }

    get cookiesAnalytics() {
        return localStorage.getItem("analitycs");
    }

    ngOnInit() {
        this.recallJsFuntions();
        if (this.userService.getTokenBearer()) {
            this.userService.getProfile().subscribe((response) => {
                this.userService.setUser(response.data);
            });
        }
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}
