import { Component, OnInit } from "@angular/core";
import { Category } from "src/app/models/category.model";
import { Course } from "src/app/models/course.model";
import { Paginator } from "src/app/models/paginator.model";
import { CategoryService } from "src/app/services/category.service";
import { CourseService } from "src/app/services/course.service";
import { Router } from "@angular/router";
import { StoreService } from "src/app/services/store.service";
import { PartnerService } from '../../../services/partner.service';
import { Partner } from '../../../models/partner.model';

@Component({
    selector: "app-home-page-one",
    templateUrl: "./home-page-one.component.html",
    styleUrls: ["./home-page-one.component.scss"],
})
export class HomePageOneComponent implements OnInit {
    constructor(
        private courseService: CourseService,
        private categoryService: CategoryService,
        private router: Router,
        private storeService: StoreService,
        private partnerService: PartnerService,
    ) {}

    public courses: Course[] = [];
    public categories: Category[] = [];
    public tabs: { category_name: string; courses: any[] }[] = [];
    public paginator: Paginator = Paginator.getDefault();
    public partners: Partner[] = [];

    ngOnInit(): void {
        if (history.state.reload) {
            location.reload();
            return;
        }

        if(this.storeService.getRedirect()=="cart"){
            this.router.navigateByUrl('/profile');
        }
        this.getCourses();
        this.getPartners();
    }

    getCourses(): void {
        let params: {} = {
            per_page: 9,
            order_by: "is_priority",
            direction: "desc"
         };

        this.courseService
            .getCourses(this.paginator, params)
            .subscribe((response) => {
                this.courses = response.data;
                this.getCategories();
            });
    }

    getPartners() {
        this.partnerService.getPartners({ is_shop: true })
            .subscribe((response: {data: Partner[]}) => {
                this.partners = response.data;
            })
    }

    getCategories(): void {
        let params: {} = { type: "course", parents: 1 };
        this.categoryService.getCategories(params).subscribe((response) => {
            this.categories = response.data;
            this.paginator = new Paginator(response.meta);
            this.setCategoriesTabs();
        });
    }

    setCategoriesTabs(): void {
        let tabs = [];

        this.categories.forEach((category) => {
            let content: Course[] = this.courses.filter((course) => {
                return course.category_id === category.id;
            });
            tabs.push({ category_name: category.name, courses: content });
        });

        this.tabs = tabs;
    }
}
