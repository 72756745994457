<div class="register-area">
    <div class="row m-0">
        <div class="col-lg-12 col-md-12 pb-70">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>
                            <h3>Únete a nosotros, crea una cuenta en U-Learning</h3>
                            <p>¿Ya tienes una cuenta? <a routerLink="/login">Ingresar</a></p>
                            <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label for="email">Correo</label>
                                            <input type="text" name="email" id="email" placeholder="INGRESE SU CORREO"
                                                class="form-control lowercase" formControlName="email"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <p *ngIf="f.email.invalid && f.email.touched" class="validation-error">Correo no
                                                válido</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="first_name">Nombre</label>
                                            <input type="text" name="first_name" id="first_name" placeholder="Ingrese su nombre"
                                                class="form-control uppercase" formControlName="first_name"
                                                [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">
                                            <p *ngIf="f.first_name.invalid && f.first_name.touched" class="validation-error">
                                                Nombre no válido</p>
                                        </div>
                                    </div>


                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="last_name">Apellido paterno</label>
                                            <input type="text" name="last_name" id="last_name"
                                                placeholder="Ingrese su apellido paterno" class="form-control uppercase"
                                                formControlName="last_name"
                                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                                            <p *ngIf="f.last_name.invalid && f.last_name.touched" class="validation-error">
                                                Apellido paterno no válido</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="second_last_name">Apellido materno</label>
                                            <input type="text" name="second_last_name" id="second_last_name"
                                                placeholder="Ingrese su apellido materno" class="form-control uppercase"
                                                formControlName="second_last_name"
                                                [ngClass]="{ 'is-invalid': submitted && f.second_last_name.errors }">
                                            <p *ngIf="f.second_last_name.invalid && f.second_last_name.touched" class="validation-error">
                                                Apellido materno no válido</p>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label>Fecha de nacimiento</label>
                                            <input formControlName="date_of_birth" type="date" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.date_of_birth.errors }">
                                            <p *ngIf="f.date_of_birth.invalid && f.date_of_birth.touched"
                                                class="validation-error">Fecha no válida</p>
                                        </div>
                                    </div>
                                   
    
                                    
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="password">Contraseña</label>
                                            <input type="password" name="password" id="password"
                                                placeholder="CREA UNA CONTRASEÑA" class="form-control"
                                                formControlName="password"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                            <p *ngIf="f.password.invalid && f.password.touched" class="validation-error">
                                                Contraseña no válida</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="password">Re-ingrese contraseña</label>
                                            <input type="password" name="password_confirmation" id="password_confirmation"
                                                placeholder="RE-INGRESE CONTRASEÑA" class="form-control"
                                                formControlName="password_confirmation"
                                                [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }">
                                            <p *ngIf="f.password_confirmation.invalid && f.password_confirmation.touched"
                                                class="validation-error">Confirmación no válida</p>
                                        </div>
                                    </div>
            
                                    <div class="term_condition">
                                        <input type="checkbox" class="" [checked]="isPrivacy" (click)="togglePivacity()"> He leído y acepto la <a target="_blank" class="link-terms"
                                            href="https://logouss.s3.us-east-2.amazonaws.com/files/Politicas_de_privacidad.pdf">Política de Privacidad</a><br/>
                                        <input type="checkbox" class="" [checked]="isTerms"  (click)="toggleTerms()"> He leído y acepto los <a target="_blank" class="link-terms"
                                            href="https://logouss.s3.us-east-2.amazonaws.com/files/Terminos_y_condiciones_de_uso.pdf">Términos y Condiciones</a><br/>
                                    </div>
                                </div>
                                

                                <button type="submit" [disabled]="!isValid" [ngClass]="!isValid == false ? 'noDisable': 'isDisable'">Crear mi cuenta</button>
                                <!-- <div class="connect-with-social">
                                    <span>Or</span>
                                    <button type="submit" class="facebook"><i class='bx bxl-facebook'></i> Connect with Facebook</button>
                                    <button type="submit" class="twitter"><i class='bx bxl-twitter'></i> Connect with Twitter</button>
                                </div> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
