import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "rating",
})
export class RatingPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value > 5) {
            value = 5;
        }
        let res = 5 - value;
        let html = ``;
        for (let i = 0; i < value; i++) {
            html += `<i class='bx bxs-star'></i>`;
        }

        for (let i = 0; i < res; i++) {
            html += `<i class='bx bxs-star default'></i>`;
        }
        return html;
    }
}
