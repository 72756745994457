<app-header-style-one></app-header-style-one>

<app-homeone-main-banner></app-homeone-main-banner>

<!-- <app-homeone-about></app-homeone-about> -->



<!-- <div class="funfacts-area pt-100">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div> -->

<div class="courses-categories-area bg-image pt-100">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Categorías de cursos</span>
            <h2 class="title">Explorar categorías</h2>
        </div>
        <app-categories-style-one [categories]="categories"></app-categories-style-one>
    </div>
</div>

<app-homeone-courses [courses]="courses" [categories]="categories" [tabs]="tabs"></app-homeone-courses>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="title">Nuestros aliados</h2>
        </div>
        <app-partner-style-one [partners]="partners"></app-partner-style-one>
    </div>
</div>
