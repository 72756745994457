import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Group } from '../models/group.model';

@Injectable({
    providedIn: 'root'
})
export class GroupService {

    constructor(private http: HttpClient) {}

    getGroups(params:{}): Observable<{
        data: Group[];
        meta: {
            pagination: any;
        };
    }> {
        let url = `${environment.apiUrl}/groups?without_is_not_shop=true`;

        return this.http.get<any>(url, {
          params: params,
     });
    }
}
