import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../services/user.service";
import { Router } from "@angular/router";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { AlertService } from "src/app/services/alert.service";

@Component({
    selector: "app-forgot-password-page",
    templateUrl: "./forgot-password-page.component.html",
    styleUrls: ["./forgot-password-page.component.scss"],
})
export class ForgotPasswordPageComponent implements OnInit {
    form: FormGroup;
    public submitted: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private alertService: AlertService,
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            email: ["", [Validators.required, Validators.email]],
        });
    }

    get f(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    onSubmit(): void {
        this.submitted = true;
        this.f.email.setValue(this.f.email.value.trim());

        if (this.form.invalid) {
            return;
        }

        this.userService
            .forgotPassword(
                this.f.email.value.toLowerCase().trim(),
            )
            .subscribe((response: { any }) => {
                this.alertService.showAlert(
                    this.alertService.typeSuccess,
                    this.alertService.passwordForgotSuccessfully
                );
                this.onReset()
            });
    }

    onReset(): void {
        this.submitted = false;
        this.form.reset();
    }
}
