import { Component, ElementRef, OnInit } from "@angular/core";
import { BlogService } from "../../../services/blog.service";
import { Paginator } from "../../../models/paginator.model";
import { Blog } from "../../../models/blog.model";
import { filter } from "rxjs/operators";

@Component({
    selector: "app-events-page",
    templateUrl: "./events-page.component.html",
    styleUrls: ["./events-page.component.scss"],
})
export class EventsPageComponent implements OnInit {
    public paginator: Paginator = Paginator.getDefault();
    public events: Blog[] = [];
    public items: any = [
        {
            name: "Enero",
            month: 1,
            selected: false,
        },
        {
            name: "Febrero",
            month: 2,
            selected: false,
        },
        {
            name: "Marzo",
            month: 3,
            selected: false,
        },
        {
            name: "Abril",
            month: 4,
            selected: false,
        },
        {
            name: "Mayo",
            month: 5,
            selected: false,
        },
        {
            name: "Junio",
            month: 6,
            selected: false,
        },
        {
            name: "Julio",
            month: 7,
            selected: false,
        },
        {
            name: "Agosto",
            month: 8,
            selected: false,
        },
        {
            name: "Setiembre",
            month: 9,
            selected: false,
        },
        {
            name: "Octubre",
            month: 10,
            selected: false,
        },
        {
            name: "Noviembre",
            month: 11,
            selected: false,
        },
        {
            name: "Diciembre",
            month: 12,
            selected: false,
        },
    ];
    private currentDate = new Date();

    private params: any = {
        type: "event",
        per_page: 5,
        date_start: "",
        date_end: "",
        is_visible: true,
        order_by: "date",
        direction: "asc"
    };

    public years: { date: number; isActive: boolean }[] = [];

    constructor(
        private blogService: BlogService,
        private element: ElementRef
    ) {}

    ngOnInit(): void {
        let currentMonth = this.currentDate.getMonth() + 1;

        let index = this.items.findIndex((i) => {
            return i.month === currentMonth;
        });

        this.selectMonthEvent(this.items[index]);

        this.blogService.getDates().subscribe((response) => {
            for (let index = 0; index < response.data.length; index++) {
                const item = response.data[index];
                this.years.push({
                    date: item.date,
                    isActive: index === 0 ? true : false,
                });
            }
            if (this.years.length === 0) {
                this.years.push({
                    date: new Date().getFullYear(),
                    isActive: true,
                });
            }
        });
    }

    getBlogs() {
        this.blogService
            .getBlogs(this.paginator, this.params)
            .subscribe((response) => {
                this.events = response.data;
                this.paginator = new Paginator(response.meta);
            });
    }

    changePage(page) {
        this.paginator.page = page;
        this.getBlogs();
        this.element.nativeElement.scrollIntoView();
    }

    selectMonthEvent(item: any) {
        let year: number;
        this.items.filter((i) => {
            if (i.month === item.month) {
                i.selected = true;
            } else {
                i.selected = false;
            }
        });
        let indexCurrentYear = this.years.findIndex((item) => {
            return item.isActive;
        });

        if (indexCurrentYear > -1) {
            year = this.years[indexCurrentYear].date;
        } else {
            year = this.currentDate.getFullYear();
        }

        let current_date_start = new Date(year, item.month, 0);
        let current_date_end = new Date(year, item.month + 1, 0);

        let date_start = `${current_date_start.getFullYear()}-${(
            "0" +
            (current_date_start.getMonth() + 1)
        ).slice(-2)}-01`;

        let date_end = `${current_date_end.getFullYear()}-${(
            "0" +
            (current_date_end.getMonth() + 1)
        ).slice(-2)}-01`;
        this.params.date_start = date_start;
        this.params.date_end = date_end;
        this.getBlogs();
    }

    existEvents() {
        return this.events.length > 0;
    }

    handleYear($event: any) {
        this.years.forEach((item) => {
            if (item.date === $event.target.value) {
                item.isActive = true;
            } else {
                item.isActive = false;
            }
        });

        let current_date_start = new Date(this.params.date_start);
        let current_date_end = new Date(this.params.date_end);

        let date_start = `${$event.target.value}-${(
            "0" +
            (current_date_start.getMonth() + 2)
        ).slice(-2)}-01`;

        let date_end = `${$event.target.value}-${(
            "0" +
            (current_date_end.getMonth() + 2)
        ).slice(-2)}-01`;

        this.params.date_start = date_start;
        this.params.date_end = date_end;
        this.getBlogs();
    }
}
