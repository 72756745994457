import { Component, OnInit } from "@angular/core";
import { AlertService } from "../../../services/alert.service";
import { StoreService } from "../../../services/store.service";
import { ShoppingService } from "../../../services/shopping.service";

@Component({
    selector: "app-shopping-cart",
    templateUrl: "./shopping-cart.component.html",
    styleUrls: ["./shopping-cart.component.scss"],
})
export class ShoppingCartComponent implements OnInit {
    constructor(
        private storeService: StoreService,
        private shoppingService: ShoppingService
    ) {}

    ngOnInit(): void {
        this.getCount();
        this.checkPendingPayments();
    }

    getCount(): number {
        return this.storeService.getPendingPayments().length;
    }

    checkCart() {
        if (this.storeService.getTokenBearer()) {
            this.shoppingService
                .refreshCart(this.storeService.getTokenBearer())
                .subscribe((response) => {
                    if (response.data && response.data.items) {
                        this.storeService.refreshCart(response.data.items);
                    }
                });
        }
    }

    checkPendingPayments() {
        if (this.storeService.getTokenBearer()) {
            this.shoppingService
                .refreshPendingPayments()
                .subscribe((response) => {
                    if (response.data) {
                        this.storeService.refreshPendingPayments(response.data);
                    }
                });
        }
    }
}
