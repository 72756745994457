import { Component, OnInit } from "@angular/core";
import { StoreService } from "../../../services/store.service";
import { PaymentService } from "../../../services/payment.service";
import { User } from "../../../models/user.model";
import { Course } from "../../../models/course.model";
import { ShoppingService } from "../../../services/shopping.service";
import { CourseService } from "../../../services/course.service";
import { PaymentItem } from "src/app/models/paymentItem.model";
import { AlertService } from "src/app/services/alert.service";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { Payment } from "src/app/models/payment.model";
import { GroupService } from "../../../services/group.service";
import { Group } from "src/app/models/group.model";
import { environment } from "src/environments/environment";
import { v4 as uuidv4 } from "uuid";
import { UserService } from "../../../services/user.service";

@Component({
    selector: "app-cart-page",
    templateUrl: "./cart-page.component.html",
    styleUrls: ["./cart-page.component.scss"],
})
export class CartPageComponent implements OnInit {
    constructor(
        private storeService: StoreService,
        private paymentService: PaymentService,
        private shoppingService: ShoppingService,
        private alertService: AlertService,
        private courseService: CourseService,
        private groupService: GroupService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute
    ) {}
    private user: User | null = null;
    public errors: [] = [];
    private course_ids: number[] = [];
    private group_ids: number[] = [];
    public total: number = 0;
    public payments: Payment[] = [];
    private queryParams: {} = {};
    private waitingPayment: Payment = null;
    timeTimer = 180;
    timer = this.timeTimer;
    time = "";

    ngOnInit(): void {
        this.user = this.storeService.getUser();
        this.route.queryParams.subscribe((params) => {
            if (params) {
                this.queryParams = params;
            }
        });

        this.getPayments();
        this.countDown();
    }

    getPayments() {
        if (!this.user) {
            this.payments = this.storeService.getPendingPayments();
            this.getItems();
            return;
        }

        this.shoppingService.refreshPendingPayments().subscribe((response) => {
            this.payments = response.data;
            this.storeService.setPendingPayments(this.payments);
            this.automaticallySubscription();
        });
    }

    getCourses() {
        let ids = this.course_ids.filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
        });
        let params = {
            ids: ids.join(","),
        };
        this.courseService.allCourses(params).subscribe((response) => {
            this.payments.forEach((payment) => {
                payment.items.forEach((item) => {
                    response.data.forEach((course: Course) => {
                        if (
                            item.model_type === "courses" &&
                            course.id === item.model_id
                        ) {
                            item.slug = course.slug;
                            item.course = { slug: course.slug };
                        }
                    });
                });
            });
        });
    }

    getGroups() {
        let ids = this.group_ids.filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
        });
        let params = {
            ids: ids.join(","),
        };
        this.groupService.getGroups(params).subscribe((response) => {
            this.payments.forEach((payment) => {
                payment.items.forEach((item) => {
                    response.data.forEach((group: Group) => {
                        if (
                            item.variant &&
                            item.variant.model_type === "groups" &&
                            group.id === item.variant.model_id
                        ) {
                            item.variant.group = { name: group.name };
                        }
                    });
                });
            });
        });
    }

    getItems() {
        this.total = 0;

        this.payments.forEach((payment) => {
            payment.items.forEach((item) => {
                item.slug = "";
                if (item.model_type === "courses") {
                    this.course_ids.push(item.model_id);
                }
                this.total += item.amount;

                if (item.variant && item.variant.model_type === "groups") {
                    this.group_ids.push(item.variant.model_id);
                }
            });
        });
        this.getCourses();
        this.getGroups();
    }

    removeItem(payment: Payment) {
        if (!this.user) {
            this.storeService.removePayment(payment);
            this.getPayments();
            return;
        }
        this.paymentService.removePendingPayment(payment.id).subscribe(
            () => {
                this.getPayments();
            },
            (error) => {
                if (error.status == 403) {
                    this.getPayments();
                }
            }
        );
    }

    generateInscription(payment: Payment) {
        if (
            this.user &&
            (this.user.phone === null ||
                this.user.documentNumber === null ||
                this.user.country_id === null ||
                this.user.first_name === null ||
                this.user.last_name === null ||
                this.user.second_last_name === null)
        ) {
            this.alertService.showAlert(
                this.alertService.typeWarning,
                this.alertService.purchaseDataUserRequired
            );
            this.router.navigate(["/profile"]);
            return;
        }
        if (!this.user) {
            this.alertService.showAlert(
                this.alertService.typeWarning,
                this.alertService.purchaseNeedBeforeRegister
            );
            const queryParams: Params = {
                payment_uuid: payment.uuid,
                redirect: "cart",
            };

            this.router.navigate(["/register"], {
                state: {
                    redirect: true,
                },
                queryParams: queryParams,
                queryParamsHandling: "merge", // remove to replace all query params by provided
            });
            return;
        }

        this.storePaymentSubscription(payment);
    }

    automaticallySubscription() {
        if (this.queryParams["payment_uuid"]) {
            let index = this.payments.findIndex((payment) => {
                return payment.uuid === this.queryParams["payment_uuid"];
            });

            this.waitingPayment = this.payments[index];

            if (index > -1 && !this.waitingPayment.payment_code) {
                this.storePaymentSubscription(this.waitingPayment);
                this.checkPaymentStatus();
            }
        }
    }

    checkPaymentStatus() {
        if (!this.waitingPayment) {
            return;
        }
        this.paymentService
            .showPayment(this.waitingPayment.id)
            .subscribe((response) => {
                if (response.data.status === "confirmed") {
                    this.generateAuthToken();
                } else {
                    setTimeout(() => {
                        this.checkPaymentStatus();
                    }, 3000);
                }
            });
    }

    storePaymentSubscription(payment: Payment) {
        let data = {
            payment_id: payment.id,
        };
        this.paymentService
            .storePaymentInscription(data)
            .subscribe((response) => {
                if (response.payment && response.payment.payment_code) {
                    this.availableCheckout(
                        response.payment,
                        response.payment.payment_code
                    );
                }
                this.getPayments();
            });
    }

    availableCheckout(payment, code) {
        this.waitingPayment = payment;

        this.goExternalPayment(code);
        this.checkPaymentStatus();
    }

    goExternalPayment(code) {
        let url = `${environment.campusPaymentUrl}?CodPago=${code}`;
        this.alertService.popupCenter(url, "ulearning", 700, 600);
    }

    generateAuthToken() {
        this.userService.generate_auth_token().subscribe((response) => {
            this.getPayments();
            window.open(
                `${environment.studentUrl}/sessions/signin-token/${response.data.token}`,
                "_blank"
            );

            this.redirectToHome();
        });
    }

    redirectToHome() {
        this.router.navigate(["/"], { state: { reload: true } });
    }

    countDown() {
        // converter 180 seconds to minutes and seconds
        let timerID = setInterval(() => {
            let minutes;
            let seconds;
            if (this.timer >= 0) {
                this.timer--;
                minutes = Math.floor(this.timer / 60);
                seconds = this.timer % 60;
            }
            if (this.timer === 0) {
                this.getPayments();
                // console.log("se acabo el tiempo");
                this.timer = this.timeTimer;
            }
            this.time = `${(minutes < 10 ? "0" : "") + minutes}:${
                (seconds < 10 ? "0" : "") + seconds
            }`;
        }, 1000);

        // setTimeout(() => {
        //     clearInterval(timerID);
        // }, this.timer * 1000);
    }

    getPrice(item: any) {
        let course_or_item = item.payment_id ? item.course : item;
        if (item.payment_id && (!course_or_item || (course_or_item && course_or_item.is_free))) {
            return 0;
        }

        if (course_or_item.coupon) {
            if (course_or_item.coupon.mode === "percentage") {
                const discount = Math.min(course_or_item.coupon.value, 100);
                return (course_or_item.amount * (100 - discount)) / 100;
            }
            const result = course_or_item.amount - course_or_item.coupon.value;
            return result > 0 ? result : 0;
        }

        return course_or_item.amount;
    }
}
