import { Injectable } from "@angular/core";
import { AlertService } from "./alert.service";
import { Course } from "src/app/models/course.model";
import { Group } from "../models/group.model";
import { PaymentItem } from "../models/paymentItem.model";
import { Payment } from "../models/payment.model";
// import { PaymentService } from "./payment.service";

@Injectable({
    providedIn: "root",
})
export class StoreService {
    constructor(
        private alertService: AlertService // private paymentService: PaymentService
    ) {}

    private ls = localStorage;
    private TOKEN: string = "bearer";
    private USER: string = "user";
    private ITEMS: string = "items";
    private REDIRECT: string = "redirect";
    private PAYMENTS: string = "payments";

    refreshCart(paymentItems: PaymentItem[]): void {
        let items = [];
        for (let i = 0; i < paymentItems.length; i++) {
            let item = paymentItems[i];
            items.push({
                model_id: item.model_id,
                title: item.title,
                amount: item.amount,
                coupon_id: item.coupon_id,
                model_type: "courses",
                quantity: 1,
                variant: item.variant ? item.variant : {},
            });
        }
        this.ls.removeItem(this.ITEMS);
        this.ls.setItem(this.ITEMS, JSON.stringify(items));
    }

    refreshPendingPayments(payment: Payment[]): void {
        let items = [];
        for (let i = 0; i < payment.length; i++) {
            let item = payment[i];
            items.push({
                id: item.id,
                payable: item.payable ? item.payable : null,
                currency: item.currency,
                status: item.status ? item.status : null,
                confirmed_at: item.confirmed_at,
                items: item.items,
            });
        }
        this.ls.removeItem(this.PAYMENTS);
        this.ls.setItem(this.PAYMENTS, JSON.stringify(items));
    }

    getCart(): PaymentItem[] {
        let items: PaymentItem[] = JSON.parse(localStorage.getItem(this.ITEMS));
        if (!items) {
            items = [];
        }

        return items;
    }

    getPendingPayments(): Payment[] {
        let items: Payment[] = JSON.parse(localStorage.getItem(this.PAYMENTS));
        if (!items) {
            items = [];
        }

        return items;
    }

    setPendingPayments(newPendingPayments: any) {
        let items = JSON.parse(localStorage.getItem(this.PAYMENTS));
        if (!!items) {
            this.ls.removeItem(this.PAYMENTS);
        }
        this.ls.setItem(this.PAYMENTS, JSON.stringify(newPendingPayments));

        return items;
    }

    setCart(newItems: any) {
        let items = JSON.parse(localStorage.getItem(this.ITEMS));
        if (!!items) {
            this.ls.removeItem(this.ITEMS);
        }
        this.ls.setItem(this.ITEMS, JSON.stringify(newItems));

        return items;
    }

    getCount(): number {
        let items = this.getCart();

        return items.length;
    }

    getTokenBearer() {
        return this.ls.getItem(this.TOKEN);
    }

    setTokenBearer(token: string) {
        return this.ls.setItem(this.TOKEN, token);
    }

    setRedirect(redirect: any) {       
        return this.ls.setItem(this.REDIRECT, redirect);
    }

    getRedirect() {       
        return this.ls.getItem(this.REDIRECT);
    }

    logout() {
        this.ls.clear();
    }

    removeItem(model_id: number) {
        let items = this.getCart();

        items = items.filter((item) => {
            return item.model_id !== model_id;
        });

        this.ls.setItem(this.ITEMS, JSON.stringify(items));

        return items;
    }

    removePayment(payment: any) {
        let payments = this.getPendingPayments();

        let indexPayments = [];

        payments.forEach((payment) => {
            payment.items.forEach((item) => {
                payment.items.forEach((itemToRemove) => {
                    if (
                        item.model_id === itemToRemove.model_id &&
                        item.model_type === itemToRemove.model_type
                    ) {
                        indexPayments.push(payments.indexOf(payment));
                    }
                });
            });
        });

        indexPayments.forEach((index) => {
            payments.splice(index, 1);
        });

        this.ls.setItem(this.PAYMENTS, JSON.stringify(payments));

        return payments;
    }

    setUser(user: any) {
        return this.ls.setItem(this.USER, JSON.stringify(user));
    }

    getUser() {
        return JSON.parse(this.ls.getItem(this.USER));
    }
}
