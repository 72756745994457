<div class="login-area">
    <div class="row m-0">
        <div class="col-lg-12 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>
                            <h3>Nueva contraseña</h3>
                            <p><a routerLink="/login">Ingresar</a></p>
                            <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="email" placeholder="Ingrese su correo" class="form-control"
                                        formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                </div>
                                <div class="form-group">
                                    <label for="password">Contraseña</label>
                                    <input type="password" placeholder="Ingrese nueva contraseña" class="form-control"
                                        formControlName="password"
                                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                </div>
                                <div class="form-group">
                                    <label for="password_confirmation">Confirma tu contraseña</label>
                                    <input type="password" placeholder="Confirme su contraseña" class="form-control"
                                        formControlName="password_confirmation"
                                        [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }">
                                </div>
                                <button type="submit">Ingresar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
