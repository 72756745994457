<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide *ngFor="let partner of partners ">
            <div class="single-partner-item">
                <a [href]="partner.link" target="_blank" class="d-block">
                    <img [src]="getImage(partner)" [alt]="partner.name">
                </a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
