import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AlertService } from "./alert.service";
import { StoreService } from "./store.service";

@Injectable({
    providedIn: "root",
})
export class SubscriptionService {
    constructor(
        private http: HttpClient,
        private storeService: StoreService,
        private alertService: AlertService
    ) {}

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getTokenBearer()}`,
        }),
    };

    getTokenBearer() {
        return this.storeService.getTokenBearer();
    }

    selfsubscription(data: {
        course_id: number;
        group_id: number;
    }): Observable<{
        data: any;
    }> {
        let url = `${environment.apiUrl}/self-subscriptions`;

        return this.http.post<any>(url, data, this.httpOptions).pipe(
            catchError((error) => {
                this.alertService.showErrors(error);
                return throwError(error);
            })
        );
    }
}
