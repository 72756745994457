import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { AlertService } from "src/app/services/alert.service";

@Component({
    selector: "app-reset-password-page",
    templateUrl: "./reset-password-page.component.html",
    styleUrls: ["./reset-password-page.component.scss"],
})
export class ResetPasswordPageComponent implements OnInit {
    form: FormGroup;
    submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            password: [
                "",
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(40),
                ],
            ],
            password_confirmation: [
                "",
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(40),
                ],
            ],
        });
    }

    newForm() {}

    get f(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    onSubmit(): void {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }

        this.userService
            .reset_my_password(
                this.f.password.value,
                this.f.password_confirmation.value
            )
            .subscribe(
                (response) => {
                    this.alertService.showAlert(
                        this.alertService.typeSuccess,
                        this.alertService.passwordUpdateSuccessfully
                    );
                    this.onReset();
                },
                (error) => {
                    if (error) {
                        this.alertService.showAlert(
                            this.alertService.typeError,
                            this.alertService.passwordUpdateError
                        );
                    }
                }
            );
    }

    onReset(): void {
        this.submitted = false;
        this.form.reset();
    }
}
