import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Category } from "src/app/models/category.model";
import { Course } from "src/app/models/course.model";

@Component({
    selector: "app-course-large-card",
    templateUrl: "./course-large-card.component.html",
    styleUrls: ["./course-large-card.component.scss"],
})
export class CourseLargeCardComponent implements OnInit {
    constructor(private router: Router) {}

    @Input() course: Course;

    ngOnInit(): void {}

    getDescription() {
        if (this.course.description_short) {
            return `${this.course.description_short.substring(0, 80)}...`;
        }

        return ``;
    }

    getPrice(course: Course) {
        if (course.is_free) {
            return 0;
        }
        return course.amount;
    }

    getImage(course: Course): string {
        if (course.main_image) {
            let original_url = course.main_image.original_url;
            if (
                original_url.indexOf("http://") !== 0 &&
                original_url.indexOf("https://") !== 0
            ) {
                original_url = `http://${original_url}`;
            }
            return original_url;
        }

        return `assets/img/courses/courses1.jpg`;
    }

    goCourses(category: Category) {
        this.router.navigate(["/courses"], {
            queryParams: { category_id: category.id },
        });
    }
}
