import { Component, OnInit } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { Category } from "src/app/models/category.model";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { UserService } from '../../../services/user.service';
import { StoreService } from '../../../services/store.service';
import { User } from '../../../models/user.model';

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    public categories: Category[] = [];

    constructor(
        private categoryService: CategoryService,
        private router: Router,
        private storeService: StoreService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.getCategories();
    }

    getUser() {
        return this.storeService.getUser();
    }

    getCategories() {
        let params: {} = { type: "course", parents: 1 };
        this.categoryService.getCategories(params).subscribe((response) => {
            this.categories = response.data;
        });
    }

    goCourses(category: Category) {
        this.router.navigate(["/courses"], {
            queryParams: { category_id: category.id },
        });
    }

    get campusUrl() {
        return environment.campusUrl;
    }

    get studentUrl() {
        return environment.studentUrl;
    }

    get intranetUrl() {
        return environment.intranetUrl;
    }

    get certificationsUrl() {
        return environment.certificationsUrl;
    }

    goStudent() {
        if (this.getUser()?.email) {
            this.userService.generate_auth_token().subscribe((response) => {
                window.open(
                    `${environment.studentUrl}/sessions/signin-token/${response.data.token}`,
                    "_blank"
                );
            });
            return
        }
        window.open(environment.studentUrl, "_blank").focus();
    }
}
