import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { Course } from "../models/course.model";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SharedService } from "./shared.service";
import { Topic } from "../models/topic.model";
import { Paginator, PaginatorResponse } from "../models/paginator.model";
import { PaginatorService } from "./paginator.service";
import { StoreService } from "./store.service";

@Injectable({
    providedIn: "root",
})
export class CourseService {
    constructor(
        private http: HttpClient,
        private paginatorService: PaginatorService,
        private storeService: StoreService
    ) {}

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getTokenBearer()}`,
        }),
    };

    getTokenBearer() {
        return this.storeService.getTokenBearer();
    }

    allCourses(params: {} = {}) {
        params = { ...params, without_pagination: true };

        return this.getCourses(Paginator.getDefault(), params);
    }

    getCourses(
        paginator: Paginator = Paginator.getDefault(),
        params: {}
    ): Observable<{
        data: Course[];
        meta: PaginatorResponse;
    }> {
        let url = `${environment.apiUrl}/courses`;
        params = this.paginatorService.transformParams(paginator, params);

        return this.http.get<any>(url, {
            params: params,
        });
    }

    findCourse(id: number): Observable<{
        data: Course;
    }> {
        let url = `${environment.apiUrl}/courses/${id}`;

        return this.http.get<any>(url, {});
    }

    findCourseBySlug(slug: string): Observable<{
        data: Course;
    }> {
        let url = `${environment.apiUrl}/courses-slug/${slug}?includes=default_billing`;

        return this.http.get<any>(url, this.httpOptions);
    }

    getTopics(
        course_id: number,
        params: {} = {}
    ): Observable<{
        data: Topic[];
        meta: {
            pagination: any;
        };
    }> {
        let url = `${environment.apiUrl}/topics_preview/${course_id}`;

        return this.http.get<any>(url, {
            params: params,
        });
    }

    getRelatives(
        course_id: number,
        params: {} = {}
    ): Observable<{
        data: Course[];
        meta: {
            pagination: any;
        };
    }> {
        let url = `${environment.apiUrl}/courses_relatives/${course_id}`;

        return this.http.get<any>(url, {
            params: params,
        });
    }

    getFeedback(course_id: number, take: number = 10) {
        let url = `${environment.apiUrl}/feedback-course/${course_id}?take=${take}?is_store=true`;

        return this.http.get<any>(url, {});
    }
}
