import { AlertService } from "src/app/services/alert.service";
import { UpperCasePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { StoreService } from "src/app/services/store.service";
import { PaymentService } from "src/app/services/payment.service";
import { ShoppingService } from "src/app/services/shopping.service";

@Component({
    selector: "app-register-page",
    templateUrl: "./register-page.component.html",
    styleUrls: ["./register-page.component.scss"],
})
export class RegisterPageComponent implements OnInit {
    form: FormGroup;
    submitted = false;
    isPrivacy = false;
    isTerms = false;

    public errors: [] = [];
    private queryParams: {} = {};

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private router: Router,
        private alertService: AlertService,
        private storeService: StoreService,
        private paymentService: PaymentService,
        private shoppingService: ShoppingService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        let token = this.userService.getTokenBearer();
        if (token) {
            this.redirectToHome();
            return;
        }

        this.route.queryParams.subscribe((params) => {
            if (params) {
                this.queryParams = params;
            }
        });

        this.form = this.formBuilder.group({
            date_of_birth: ["", Validators.required],
            email: ["", [Validators.required, Validators.email]],
            first_name: [
                "",
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(40),
                ],
            ],
            last_name: [
                "",
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(40),
                ],
            ],
            second_last_name: [
                "",
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(40),
                ],
            ],
            password: [
                "",
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(40),
                ],
            ],
            password_confirmation: [
                "",
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(40),
                ],
            ],
        });
    }

    get f(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    onSubmit(): void {
        this.submitted = true;

        this.f.email.setValue(this.f.email.value.trim());
        this.f.password.setValue(this.f.password.value.trim());
        this.f.password_confirmation.setValue(
            this.f.password_confirmation.value.trim()
        );
        this.f.first_name.setValue(this.f.first_name.value.trim());
        this.f.last_name.setValue(this.f.last_name.value.trim());
        this.f.second_last_name.setValue(this.f.second_last_name.value.trim());

        if (this.form.invalid) {
            return;
        }

        this.userService
            .register(
                this.f.date_of_birth.value,
                this.f.email.value.toLowerCase(),
                this.f.password.value,
                this.f.password_confirmation.value,
                this.f.first_name.value.toUpperCase(),
                this.f.last_name.value.toUpperCase(),
                this.f.second_last_name.value.toUpperCase()
            )
            .subscribe(
                (response: { expired_at: string; token: string }) => {
                    if (response.token) {
                        this.userService.setTokenBearer(response.token);
                        let token = this.userService.getTokenBearer();
                        if (token) {
                            this.userService
                                .getProfile(token)
                                .subscribe((response) => {
                                    if (response) {
                                        if (response.data) {
                                            this.userService.setUser(
                                                response.data
                                            );
                                            this.alertService.showAlert(
                                                this.alertService.typeSuccess,
                                                this.alertService
                                                    .registerSuccessfully
                                            );
                                            this.checkPendingPayments(token);

                                            this.shoppingService
                                                .refreshPendingPayments(token)
                                                .subscribe((response) => {
                                                    if (response.data) {
                                                        this.storeService.refreshPendingPayments(
                                                            response.data
                                                        );

                                                        setTimeout(() => {
                                                            this.redirectToHome();
                                                        }, 300);
                                                    }
                                                });
                                        }
                                    }
                                });
                        }
                    }
                },
                (error) => {
                    if (error.status === 409) {
                        this.router.navigate(["/login"]);
                    }
                }
            );
    }

    checkPendingPayments(token: string) {
        this.paymentService.persistPendingCart(token);
    }

    redirectToHome() {
        if (
            this.queryParams["redirect"] &&
            this.queryParams["redirect"] === "cart"
        ) {
            const queryParams: Params = {
                payment_uuid: this.queryParams["payment_uuid"],
                redirect: "cart",
            };
            this.router.navigate(["/profile"], {
                state: { reload: true },
                queryParams: queryParams,
                queryParamsHandling: "merge", // remove to replace all query params by provided
            });

            return;
        }

        this.router.navigate(["/"], { state: { reload: true } });
    }

    onReset(): void {
        this.submitted = false;
        this.form.reset();
    }

    get isValid(): boolean {
        if (this.isPrivacy && this.isTerms) {
            return true;
        }
    }

    togglePivacity() {
        this.isPrivacy = !this.isPrivacy;
    }
    toggleTerms() {
        this.isTerms = !this.isTerms;
    }
}
