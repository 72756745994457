<div class="card">
    <div class="card-header">
        File Upload
    </div>
    <div class="card-body">
        <div>
            <!-- Display files names -->
            <input matInput [(ngModel)]="fileAttr" readonly name="name" />

            <!-- Browse Button -->
            <button mat-flat-button color="primary">
                Browse File
            </button>

            <!-- Fetch selected filed on change -->
            <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)" name="uploadFile"
                multiple="multiple" accept="image/*" />
        </div>
    </div>

    <div class="card-footer">
        <div><img src="{{dataimage}}" width="300px"></div>
    </div>
</div>