import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Course } from "src/app/models/course.model";
import { Group } from "src/app/models/group.model";
import { CourseService } from "src/app/services/course.service";
import { GroupService } from "src/app/services/group.service";
import { Topic } from "../../../models/topic.model";
import { OwlOptions } from "ngx-owl-carousel-o";
import { Category } from "src/app/models/category.model";
import { Meta, Title } from "@angular/platform-browser";
import { Feedback } from "../../../models/feedback.model";
import format from "date-fns/format";
import * as moment from "moment";
@Component({
    selector: "app-courses-details-page",
    templateUrl: "./courses-details-page.component.html",
    styleUrls: ["./courses-details-page.component.scss"],
})
export class CoursesDetailsPageComponent implements OnInit {
    private _routerSub = Subscription.EMPTY;
    public images: string[] = [];
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private courseService: CourseService,
        private groupService: GroupService,
        private title: Title,
        private meta: Meta
    ) {
        this._routerSub = this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                const params = val.url.split("/");

                if (params[1] === "courses") {
                    this.fetchCourse();
                }
            }
        });
    }

    public course: Course;
    public groups: Group[] = [];
    public group_id: number | null = null;
    public group: Group | null = null;
    public topics: Topic[] = [];
    public relatives: Course[] = [];
    public missionSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        items: 1,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>",
        ],
    };
    subscriptions: Subscription[] = [];

    public feedback_moderates: Feedback[] = [];
    private feedback_take: number = 10;

    ngOnInit(): void {

    }

    ngOnDestroy() {
        this._routerSub.unsubscribe();

        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    fetchCourse() {
        const slug = this.route.snapshot.params["slug"];
        this.findCourseBySlug(slug);
    }

    findCourseBySlug(slug: string): void {
        this.images = [];

        this.subscriptions.push(
            this.courseService.findCourseBySlug(slug).subscribe((response) => {
                this.course = response.data;
                this.initMetadata();
                if (this.course && this.course.main_image) {
                    let original_url = this.sanitizeUrl(
                        this.course.main_image.original_url
                    );
                    this.images.push(original_url);
                }

                if (this.course && this.course.secondary_images) {
                    let secondary_images: any = Object.entries(
                        this.course.secondary_images
                    );
                    for (
                        let index = 0;
                        index < secondary_images.length;
                        index++
                    ) {
                        const element = secondary_images[index];
                        let original_url = this.sanitizeUrl(
                            element[1].original_url
                        );

                        this.images.push(original_url);
                    }
                }

                if (this.images.length === 0) {
                    this.images.push(`assets/img/black-logo.png`);
                }

                this.getGroups(this.course.id);
                this.getTopics(this.course.id);
                this.getRelatives(this.course.id);
                this.getFeedback();
            })
        );
    }

    initMetadata() {
        let image = this.getMainImage(this.course);
        let description = this.course.description_short ? this.course.description_short : "Curso virtual en la plataforma U-Learning";

        this.title.setTitle(this.course.title);

        this.meta.updateTag({
            itemprop: "name",
            content: this.course.title,
        });
        this.meta.updateTag({
            itemprop: "description",
            content: description,
        });
        this.meta.updateTag({
            itemprop: "image",
            content: image,
        });

        this.meta.updateTag({
            name: "description",
            content: description,
        });
        this.meta.updateTag({
            name: "twitter:card",
            content: "summary",
        });
        this.meta.updateTag({
            name: "twitter:site",
            content: "U-Learning",
        });

        this.meta.updateTag({
            property: "og:type",
            content: "website",
        });
        this.meta.updateTag({
            property: "og:site_name",
            content: "U-Learning",
        });
        this.meta.updateTag({
            property: "og:title",
            content: this.course.title,
        });
        this.meta.updateTag({
            property: "og:image",
            content: image
        });
        this.meta.updateTag({
            property: "og:description",
            content: description,
        });

        this.meta.updateTag({
            property: "twitter:title",
            content: this.course.title,
        });
        this.meta.updateTag({
            property: "twitter:description",
            content: description,
        });
        this.meta.updateTag({
            property: "twitter:image",
            content: image
        });
    }

    sanitizeUrl(original_url: string) {
        if (
            original_url.indexOf("http://") !== 0 &&
            original_url.indexOf("https://") !== 0
        ) {
            original_url = `http://${original_url}`;
        }

        return original_url;
    }

    getTopics(course_id: number) {
        this.subscriptions.push(
            this.courseService.getTopics(course_id).subscribe((response) => {
                this.topics = response.data;
            })
        );
    }

    getGroups(course_id: number) {
        this.subscriptions.push(
            this.groupService.getGroups({ course_id }).subscribe((response) => {
                this.groups = response.data;
                if (this.groups.length > 0) {
                    this.selectGroup(this.groups[0].id);
                }
            })
        );
    }

    getRelatives(course_id: number) {
        this.subscriptions.push(
            this.courseService.getRelatives(course_id).subscribe((response) => {
                this.relatives = response.data;
            })
        );
    }

    getFeedback() {
        this.courseService
            .getFeedback(this.course.id, this.feedback_take)
            .subscribe((response) => {
                this.feedback_moderates = response.data;
            });
    }

    selectGroup(group_id: number): void {
        this.group_id = group_id;
        let index = this.groups.findIndex((group) => {
            return group.id === group_id;
        });

        this.group = this.groups[index];
    }

    toTime(value: string): Date {
        return new Date(`2022/01/01 ${value}`);
    }

    addToCard(course: Course) {
        this.validateAddToCard();
    }

    validateAddToCard() {
        if (!this.group_id) {
            console.log("Elija un horario para continuar");
        }
    }

    getMainImage(course: Course): string {
        if (course && course.main_image) {
            let original_url = course.main_image.original_url;
            if (
                original_url.indexOf("http://") !== 0 &&
                original_url.indexOf("https://") !== 0
            ) {
                original_url = `http://${original_url}`;
            }
            return original_url;
        }

        return `assets/img/black-logo.png`;
    }

    goCourses(category: Category) {
        this.router.navigate(["/courses"], {
            queryParams: { category_id: category.id },
        });
    }

    isMoreFeedback() {
        return this.feedback_moderates?.length < this.course?.rating_count;
    }

    toggleFeedback() {
        if (this.isMoreFeedback()) {
            this.feedback_take = this.course?.rating_count;
        } else {
            this.feedback_take = 10;
        }
        this.getFeedback();
    }

    getPeriod(group: Group): string {
        let start = ""

        if (group.date_start) {
            start = moment(group.date_start).format("DD/MM/yyyy");
        }

        if (group.date_until) {
            let until = moment(group.date_until).format("DD/MM/yyyy");
            return `${start} al ${until} `;
        }
        return `Inicia el ${start} `;
    }

    hasSchedules(group: Group): boolean {
        return group.schedules && group.schedules.length > 0;
    }

    get moreInfo(): boolean {
        return (
            !!this.course?.methodology ||
            !!this.course?.benefits ||
            !!this.course?.instructions ||
            !!this.course?.target
        );
    }

    get showGroups() {
        if (!this.course && !this.groups) {
            return;
        }

        return (
            this.course?.modality !== "recorded" ||
            (this.course?.modality === "recorded" &&
                this.groups &&
                this.groups.length >= 1)
        );
    }
}
