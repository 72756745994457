<app-header-style-one></app-header-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><a (click)="goCourses(course.category)" class="pointer">{{ course?.category.name }}</a></li>
            </ul>
            <div class="courses-title">
                <h2>{{ course?.title }}</h2>
                <div class="courses-review">
                    <div class="review-stars">
                        <div *ngIf="course" [outerHTML]="course?.rating_average | rating"></div>
                    </div>
                    <span class="reviews-total d-inline-block">({{ course?.rating_count }})</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="courses-details-area pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-md-8">

                    <div class="courses-meta">

                        <!-- <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Categoría</span>
                                <a routerLink="/single-courses">{{ course?.category.name }}</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Estudiantes registrados</span>
                                <a routerLink="/single-courses">{{ course?.students_count }}</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Last Updated</span>
                                <a routerLink="/single-courses">01/14/2019</a>
                            </li>
                        </ul> -->
                    </div>
                </div>
                <!-- <div class="col-md-4">
                    <div class="courses-review">
                        <div class="review-stars">
                            <div *ngIf="course" [outerHTML]="course?.rating_average | rating"></div>
                        </div>
                        <span class="reviews-total d-inline-block">({{ course?.rating_count }})</span>
                    </div>
                </div> -->
                <div class="col-lg-7">
                    <div class="courses-details-image text-center">

                        <div class="mission-slides">
                            <owl-carousel-o [options]="missionSlides">
                                <ng-template carouselSlide 
                                *ngFor="let image of images">
                                    <img src="{{ image }}" alt="image">
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 mb-auto courses-summary-main">
                    <app-course-summary [course]="course" [group]="group" [groups]="groups"></app-course-summary>
                </div>
                <div class="col-lg-12">
                    <div class="courses-details-desc">
                        <h3>Descripción</h3>
                        <div [innerHTML]="course?.description_large"></div>
                    </div>
                    <br>
                    <div class="col-lg-4 mb-auto courses-summary-auxiliar">
                        <app-course-summary [course]="course" [group]="group" [groups]="groups"></app-course-summary>
                    </div>
                    <br>
                    <div class="my-dashboard-area">
                        <div class="container" *ngIf="showGroups">
                            <div class="myDashboard-content">
                                <div class="orders-table table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Fechas</th>
                                                <th>Grupo</th>
                                                <th>Horarios</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let group of groups">
                                                <td>{{ getPeriod(group) }}</td>
                                                <td>{{ group?.name }}</td>
                                                <td>
                                                    <span class="schedules-empty" *ngIf="!hasSchedules(group)">Horario
                                                        libre</span>
                                                    <ul *ngIf="hasSchedules(group)">
                                                        <li *ngFor="let schedule of group?.schedules">{{ schedule.day |
                                                            translate}} de {{ toTime(schedule.start) | date: 'shortTime'
                                                            }}
                                                            hasta {{ toTime(schedule.until) | date: 'shortTime' }}</li>
                                                    </ul>
                                                </td>
                                                <td class="detail-actions-buttons"><button
                                                        (click)="selectGroup(group.id)"
                                                        [ngClass]="{ 'view-button' : group_id !== group.id, 'view-button active' : group_id === group.id }">{{
                                                        group_id === group.id ? "Seleccionado" : "Seleccionar"
                                                        }}</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="courses-sidebar-syllabus">
                            <h3>Contenido</h3>
                            <div *ngFor="let topic of topics">
                                <h4 class="chapter"><i class='bx bx-check-double'></i> {{
                                    topic.title }}</h4>
                                <div class="row">
                                    <div class="about-text">
                                        <ul class="requirements-list">
                                            <li *ngFor="let item of topic.children">
                                                {{ item.title }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="topics.length === 0">Información no disponible</div>
                        </div>

                        <div class="courses-sidebar-syllabus" *ngIf="moreInfo">
                            <h3 *ngIf="course?.methodology">Metodología</h3>
                            <div>
                                <div class="row">
                                    <div [innerHTML]="course?.methodology">

                                    </div>
                                </div>
                            </div>
                            <hr>

                            <h3 *ngIf="course?.benefits">Beneficios</h3>
                            <div>
                                <div class="row">
                                    <div [innerHTML]="course?.benefits">

                                    </div>
                                </div>
                            </div>
                            <hr>

                            <h3 *ngIf="course?.instructions">Instrucciones</h3>
                            <div>
                                <div class="row">
                                    <div [innerHTML]="course?.instructions">

                                    </div>
                                </div>
                            </div>
                            <hr>

                            <h3 *ngIf="course?.target">Dirigido a</h3>
                            <div>
                                <div class="row">
                                    <div [innerHTML]="course?.target">

                                    </div>
                                </div>
                            </div>
                            <div *ngIf="topics.length === 0">Información no disponible</div>
                        </div>
                    </div>
                    <!-- <h3>Instructor</h3>
                    <div *ngIf="group === null">
                        <p>Seleccione un horario</p>
                    </div>
                    <div class="courses-author" *ngFor="let teacher of group?.teachers">
                     <div class="author-profile-header"></div> 
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="assets/img/user1.jpg" class="shadow-sm rounded-circle" alt="image"> 
                                <div class="author-profile-title-details d-flex justify-content-between">
                                    <div class="author-profile-details">
                                        <h4>{{ teacher.first_name }} {{ teacher.last_name }}</h4>
                                        <span class="d-block">{{ teacher.first_name }} {{ teacher.last_name }}</span> 
                                    </div>
                                    <div class="author-profile-raque-profile">
                                        <a routerLink="/single-instructor" class="d-inline-block">View Profile on U-Learning</a>
                                    </div> 
                                </div>
                            </div>
                           <p>James Anderson is a celebrated photographer, author, and teacher who brings passion to everything he does.</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> 
                        </div>
                    </div> -->
                    <div class="courses-review-comments">
                        <h3>{{ course?.rating_count }} Valoraciones al curso</h3>
                        <div *ngFor="let feedback of feedback_moderates">
                            <div class="user-review">
                                <div class="review-rating">
                                    <div class="review-stars">
                                        <div *ngIf="course" [outerHTML]="feedback.rating | rating"></div>
                                    </div>
                                </div>
                                <span class="d-inline-block sub-comment">{{ feedback.user.first_name }} {{
                                    feedback.user.last_name }}</span>
                                <p>{{ feedback.comment }}</p>
                            </div>
                        </div>
                        <div class="user-review text-center" *ngIf="course?.rating_count > 0">
                            <a (click)="toggleFeedback()" class="read-more-btn pointer">{{ isMoreFeedback() ? 'Ver todo'
                                : 'Ver
                                menos'}}
                                <i class='bx'
                                    [ngClass]="{'bx-down-arrow-alt': isMoreFeedback(), 'bx-up-arrow-alt': !isMoreFeedback() }"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="related-courses">
                    <h3>Cursos relacionados</h3>
                    <div class="row">
                        <div class="col-lg-4 col-md-6" *ngFor="let relative of relatives">
                            <app-course-card [course]="relative"></app-course-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
