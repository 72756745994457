import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Country } from "src/app/models/country.model";
import { UserService } from "src/app/services/user.service";
import { User } from "../../../models/user.model";
import { AlertService } from "../../../services/alert.service";
import { getGeo } from "geoplugin";
import { StoreService } from "src/app/services/store.service";

@Component({
    selector: "app-edit-account-page",
    templateUrl: "./edit-account-page.component.html",
    styleUrls: ["./edit-account-page.component.scss"],
})
export class EditAccountPageComponent implements OnInit {
    public form: FormGroup;
    public submitted = false;
    public user: User = null;
    public avatarUrl: string = "";
    public countries: Country[] = [];
    public countryIsValid: boolean = true;
    private queryParams: {} = {};
    public codeCountry: any;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private router: Router,
        private alertService: AlertService,
        private storeService: StoreService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        if (history.state.reload) {
            location.reload();
            return;
        }

        this.route.queryParams.subscribe((params) => {
            if (params) {
                this.queryParams = params;
            }
        });

        this.getUser();
    }

    getUser() {
        let token = this.userService.getTokenBearer();
        if (token) {
            this.user = this.userService.getUser();
            if (this.user.avatarUrl) {
                this.avatarUrl = this.user.avatarUrl;
            }
            this.buildItem(this.user);

            this.getCountries();
        } else {
            this.redirectToHome();
        }
    }

    refreshUser() {
        let token = this.userService.getTokenBearer();
        if (token) {
            this.userService.getProfile(token).subscribe((response) => {
                this.userService.setUser(response.data);
                this.user = this.userService.getUser();
                if (this.user.avatarUrl) {
                    this.avatarUrl = this.user.avatarUrl;
                }
            });
        }
    }

    getCountries() {
        this.userService.getCountries().subscribe((res: any) => {
            this.countries = res.data;
            let code;
            // Default value

            code = 'PE';
            this.countries.filter((country) => {
                if (country.code === code) {
                    this.codeCountry = country.id;
                }
            });
            this.buildItem(this.user);
        });
    }
    buildItem(user: User) {
        this.form = this.formBuilder.group({
            date_of_birth: [user.date_of_birth, [Validators.required]],
            first_name: [
                user.first_name,
                [Validators.required, Validators.minLength(3)],
            ],
            last_name: [
                user.last_name,
                [Validators.required, Validators.minLength(2)],
            ],
            second_last_name: [
                user.second_last_name,
                [Validators.required, Validators.minLength(2)],
            ],
            document_number: [
                user.document_number || "",
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(9),
                ],
            ],
            document_type: [user.document_type || "DNI"],
            address: [user.address || "", [Validators.minLength(3)]],
            phone: [
                user.phone || "",
                [
                    Validators.required,
                    Validators.minLength(6),
                    Validators.maxLength(13),
                ],
            ],
            email: [user.email, [Validators.required, Validators.email]],
            country: [user.country || null],
            country_id: [
                user.country_id || this.codeCountry,
                [Validators.required],
            ],
            gender: [user.gender || "M", [Validators.required]],
        });
    }

    redirectToHome() {
        this.router.navigate(["/"], { state: { reload: true } });
    }
    redirectCart() {
        this.router.navigate(["/cart"]);
    }
    get f(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    onSubmit(): void {
        this.submitted = true;
        this.countryIsValid = true;

        if (this.form.invalid) {
            if (this.f.country_id.value === null) {
                this.countryIsValid = false;
            }
            return;
        }

        this.userService
            .update(
                this.f.date_of_birth.value,
                this.f.email.value.toLowerCase(),
                this.f.first_name.value.toUpperCase(),
                this.f.last_name.value.toUpperCase(),
                this.f.second_last_name.value.toUpperCase(),
                this.f.document_number.value,
                this.f.document_type.value,
                this.f.address.value,
                this.f.phone.value,
                this.f.country_id.value,
                this.f.gender.value
            )
            .subscribe((response) => {
                if (response) {
                    if (response.data) {
                        this.alertService.showAlert(
                            this.alertService.typeSuccess,
                            this.alertService.profileUpdateSuccessfully
                        );
                        this.refreshUser();
                        if (
                            this.queryParams["redirect"] &&
                            this.queryParams["redirect"] === "cart"
                        ) {
                            const queryParams: Params = {
                                payment_uuid: this.queryParams["payment_uuid"],
                                redirect: "cart",
                            };
                            this.router.navigate(["/cart"], {
                                state: { reload: true },
                                queryParams: queryParams,
                                queryParamsHandling: "merge", // remove to replace all query params by provided
                            });

                            return;
                        }
                    } else {
                        this.alertService.showAlert(
                            this.alertService.typeError,
                            this.alertService.profileUpdateError
                        );
                    }
                }
            });
    }

    name = "Foto de perfil";
    @ViewChild("fileInput") fileInput: ElementRef;
    fileAttr = "Elija una imagen";

    uploadFile(imgFile: any) {
        if (imgFile.target.files && imgFile.target.files[0]) {
            this.fileAttr = "";
            Array.from(imgFile.target.files).forEach((file: File) => {
                this.fileAttr += file.name;
            });

            let reader = new FileReader();
            reader.onload = (e: any) => {
                let image = new Image();
                image.src = e.target.result;
                image.onload = (rs) => {
                    let imgBase64Path = e.target.result;
                    this.avatarUrl = imgBase64Path;

                    this.storeAvatar(imgBase64Path);
                };
            };
            reader.readAsDataURL(imgFile.target.files[0]);

            // Reset if duplicate image uploaded again
            this.fileInput.nativeElement.value = "";
        } else {
            this.fileAttr = "Elija una imagen";
        }
    }

    storeAvatar(avatar: string) {
        this.userService.storeAvatar(avatar).subscribe((response) => {
            if (response) {
                this.refreshUser();
                this.userService.setUser(response.data);
            }
        });
    }
}
