import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-funfacts",
    templateUrl: "./funfacts.component.html",
    styleUrls: ["./funfacts.component.scss"],
})
export class FunfactsComponent implements OnInit {
    @Input() items: [] = [];
    @Output() selectMonthEvent = new EventEmitter<[]>();

    constructor() {}

    ngOnInit(): void {}

    selectMonth(item: []) {
        this.selectMonthEvent.emit(item);
    }
}
