<div class="banner-wrapper">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="wrapper-content">
                    <h1>U-LEARNING</h1>
                    <p>Estudia a tu ritmo y con tu propio estilo</p>
                </div>
            </div>
        </div>
    </div>
</div>