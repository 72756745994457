import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { BlogService } from "../../../services/blog.service";
import { Blog } from "../../../models/blog.model";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
    selector: "app-events-details-page",
    templateUrl: "./events-details-page.component.html",
    styleUrls: ["./events-details-page.component.scss"],
})
export class EventsDetailsPageComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private blogService: BlogService
    ) {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.fetchEvent();
            }
        });
    }
    public missionSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        items: 1,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>",
        ],
    };
    public images: string[] = [];
    public blog: Blog | null = null;
    subscriptions: Subscription[] = [];

    ngOnInit(): void {
        this.fetchEvent();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    fetchEvent() {
        const id = this.route.snapshot.params["id"];
        this.findEvent(id);
    }

    findEvent(id: number): void {
        this.subscriptions.push(
            this.blogService.findBlog(id).subscribe((response) => {
                this.blog = response.data;
                this.getImages(this.blog);
            })
        );
    }

    getImages(blog: Blog): void {
        this.images = [];

        if (blog && blog.main_image) {
            let original_url = this.sanitizeUrl(
                blog.main_image.original_url
            );
            this.images.push(original_url);
        }

        if (blog && blog.secondary_images) {
            let secondary_images: any = Object.entries(
                blog.secondary_images
            );
            for (
                let index = 0;
                index < secondary_images.length;
                index++
            ) {
                const element = secondary_images[index];
                let original_url = this.sanitizeUrl(
                    element[1].original_url
                );

                this.images.push(original_url);
            }
        }

        if (this.images.length === 0) {
            this.images.push(`assets/img/avatar.jpg`);
        }
    }

    sanitizeUrl(original_url: string) {
        if (
            original_url.indexOf("http://") !== 0 &&
            original_url.indexOf("https://") !== 0
        ) {
            original_url = `http://${original_url}`;
        }

        return original_url;
    }
}
