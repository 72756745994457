<div class="login-area">
    <div class="row m-0">
        <div class="col-lg-12 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>
                            <h3>Hola de nuevo</h3>
                            <p>¿Nuevo en U-Learning? <a routerLink="/register">Registrarse</a></p>
                            <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="email" placeholder="Ingrese su correo" class="form-control"
                                        formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                </div>
                                <div class="form-group">
                                    <label for="password">Contraseña</label>
                                    <input type="password" placeholder="Ingrese su contraseña" class="form-control"
                                        formControlName="password"
                                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                </div>
                                <button type="submit">Ingresar</button>

                                <div class="form-group">
                                    <div id="g_id_onload"
                                        data-client_id="1047857322286-0pcu8es00b4vfmrrei9aqt7nq3blh4o5.apps.googleusercontent.com"
                                        data-context="signin" data-ux_mode="popup" data-auto_prompt="false">
                                    </div>
                                </div>

                                <div class="forgot-password">
                                    <a routerLink="/forgot-password">¿Olvidó su contraseña?</a>
                                </div>

                                <div class="form-group">
                                    <hr>
                                </div>

                                <div class="form-group btn-google-container">
                                    <div id="googleButton"></div>
                                </div>
                                <div class="connect-with-social">
                                    <button type="button" (click)="loginWithFacebook()" class="facebook"><i
                                            class='bx bxl-facebook'></i> Iniciar con Facebook</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
