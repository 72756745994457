<app-header-style-one></app-header-style-one>
<div class="error-404-area">
    <div class="container">
        <div class="notfound">
            <div class="notfound-bg">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h1>Oops :(</h1>
            <h3>Página no encontrada</h3>
            <a routerLink="/" class="default-btn">
                <i class='bx bx-home-circle icon-arrow before'></i>
                <span class="label">Regresar al inicio</span>
                <i class="bx bx-home-circle icon-arrow after"></i>
            </a>
        </div>
    </div>
</div>