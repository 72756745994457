import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Payment } from "../models/payment.model";
import { StoreService } from "./store.service";

@Injectable({
    providedIn: "root",
})
export class ShoppingService {
    constructor(private http: HttpClient, private storeService: StoreService) {}

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getTokenBearer()}`,
        }),
    };

    getTokenBearer() {
        return this.storeService.getTokenBearer();
    }

    refreshCart(token: string = null): Observable<{ data: Payment }> {
        let url = `${environment.apiUrl}/my-cart`;

        if (token) {
            this.storeService.setTokenBearer(token);
        }

        let httpOptions: { headers: HttpHeaders };
        if (token) {
            httpOptions = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            };
        } else {
            httpOptions = this.httpOptions;
        }

        return this.http.get<any>(url, httpOptions);
    }

    refreshPendingPayments(token: string = null): Observable<{ data: Payment[] }> {
        let url = `${environment.apiUrl}/my-ecommerce-payments`;

        if (token) {
            this.storeService.setTokenBearer(token);
        }

        let httpOptions: { headers: HttpHeaders };
        if (token) {
            httpOptions = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            };
        } else {
            httpOptions = this.httpOptions;
        }

        return this.http.get<any>(url, httpOptions);
    }
}
