export const environment = {
    production: true,
    apiUrl: "https://api.ulearning.com.pe/api",
    baseUrl: "https://ulearning.com.pe",
    studentUrl: "https://student.ulearning.com.pe",
    intranetUrl: "https://intranet.ulearning.com.pe",
    campusUrl: "https://ulearning.pe",
    certificationsUrl: "https://student.ulearning.com.pe/certifications",
    campusPaymentUrl:
        "https://campus.uss.edu.pe/29_NewInsVirt/frmInsExtPago.aspx",

    tokenFacebook: "743887196605416",
    tokenGoogle: "1047857322286-0pcu8es00b4vfmrrei9aqt7nq3blh4o5.apps.googleusercontent.com",
};
