import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "currency",
})
export class CurrencyPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value === 0) {
            if (args) {
                return "Matrícula gratis";
            }
            return "Gratis";
        }
        return `S/ ${Number(value.toFixed(2))}`;
    }
}
