<div class="courses-categories-slides">
    <owl-carousel-o [options]="categoriesSlides">
        <ng-template carouselSlide *ngFor="let category of categories">
            <div class="single-categories-courses-box mb-30" [ngStyle]="changeBackground(category.color)">
                <div class="icon">
                    <img src="{{getImage(category)}}" alt="image">
                </div>
                <h3>{{ category.name }}</h3>
                <a (click)="goCourses(category)" class="link-btn pointer"></a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>