<app-header-style-one></app-header-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><a [routerLink]="['/events']">Eventos</a></li>
            </ul>
            <h2>{{ blog?.title }} </h2>
        </div>
    </div>
</div>

<div class="events-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="events-details-header">
                    <a routerLink="/events" class="back-all-events"><i class='bx bx-chevrons-left'></i> Ver todos los
                        eventos</a>
                    <h3>{{ blog?.title }}</h3>
                    <ul class="events-info-meta d-none">
                        <li><i class="flaticon-timetable"></i> 10 November, 2020</li>
                        <li><i class="far fa-clock"></i> 10.00AM - 10.00PM</li>
                    </ul>
                    <div class="events-meta">
                        <ul>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Fecha</span>
                                {{ blog?.date | date: 'dd/MM/yyyy' }}
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Hora</span>
                                {{ blog?.date | date: 'h:mm a' }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="events-details">
                    
                    <div class="events-details-image">
                        <div class="mission-slides">
                            <owl-carousel-o [options]="missionSlides">
                                <ng-template carouselSlide 
                                *ngFor="let image of images">
                                    <img src="{{ image }}" alt="image">
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>



                    <div class="events-details-desc" [innerHTML]="blog?.description_large">
                    </div>

                    <!-- <div class="ednuv-post-navigation">
                        <div class="prev-link-wrapper">
                            <div class="info-prev-link-wrapper">
                                <a routerLink="/single-events">
                                    <span class="image-prev">
                                        <img src="assets/img/avatar.jpg" alt="image">
                                        <span class="post-nav-title">Prev</span>
                                    </span>
                                    <span class="prev-link-info-wrapper">
                                        <span class="prev-title">Don't buy a tech gift until you read these rules</span>
                                        <span class="meta-wrapper">
                                            <span class="date-post">January 21, 2020</span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="next-link-wrapper">
                            <div class="info-next-link-wrapper">
                                <a routerLink="/single-events">
                                    <span class="next-link-info-wrapper">
                                        <span class="next-title">The golden rule of buying a phone as a gift</span>
                                        <span class="meta-wrapper">
                                            <span class="date-post">January 21, 2020</span>
                                        </span>
                                    </span>
                                    <span class="image-next">
                                        <img src="assets/img/avatar.jpg" alt="image">
                                        <span class="post-nav-title">Next</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_events_details">
                        <h3 class="widget-title">Detalles</h3>
                        <ul>
                            <li><span>Categoría:</span> {{ blog?.category.name }}</li>
                            <li><span>Fecha:</span> {{ blog?.date | date: 'dd/MM/yyyy' }}</li>
                            <li><span>Hora:</span> {{ blog?.date | date: 'h:mm a' }}</li>
                            <li *ngIf="blog?.author"><span>Ponente:</span> {{ blog?.author }} </li>
                            <li *ngIf="blog?.link_event"><span>Evento:</span>
                                <a href="{{ blog?.link_event }}" target="_blank"><i class="bx bx-log-in"></i> Link de evento</a></li>
                            <li *ngIf="blog?.is_link_event_visible"><span>Registro:</span>
                                <a href="{{ blog?.link_register }}" target="_blank"><i class="bx bx-log-in"></i> Link de registro</a></li>
                        </ul>
                    </div>
                    <div class="widget widget_events_details" *ngIf="blog?.location">
                        <h3 class="widget-title">Lugar</h3>
                        <ul>
                            <li>{{ blog?.location }}</li>
                        </ul>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>
