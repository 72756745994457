import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../services/user.service";
import { Router } from "@angular/router";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { PaymentService } from "../../../services/payment.service";
import { ShoppingService } from "../../../services/shopping.service";
import { StoreService } from "src/app/services/store.service";
import { CredentialResponse, PromptMomentNotification } from "google-one-tap";
import { environment } from "../../../../environments/environment.dev";

import {
    SocialAuthService,
    FacebookLoginProvider,
    SocialUser,
} from "angularx-social-login";

@Component({
    selector: "app-login-page",
    templateUrl: "./login-page.component.html",
    styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
    form: FormGroup;
    public submitted: boolean = false;
    private socialUser!: SocialUser;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private router: Router,
        private paymentService: PaymentService,
        private storeService: StoreService,
        private shoppingService: ShoppingService,
        private socialAuthService: SocialAuthService
    ) {}

    ngOnInit(): void {
        let token = this.userService.getTokenBearer();
        if (token) {
            this.redirectToHome();
        }
        this.form = this.formBuilder.group({
            email: ["", [Validators.required, Validators.email]],
            password: [
                "",
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(40),
                ],
            ],
        });

        this.socialAuthService.authState.subscribe((response) => {
            /**
             *
             * Structure for
             * response:
             * {
             *   "id": "5653762757996597",
             *   "name": "Joe Doe",
             *   "email": "joe.doe@hotmail.com",
             *   "photoUrl": "https://graph.facebook.com/5653762997996597/picture?type=normal",
             *   "firstName": "Joe",
             *   "lastName": "Doe",
             *   "authToken": "EAAKkj7wbSZBgBCEPAQPTe5eCebz7D4jYZA5UAim5Bc8AiKXIfRiOz0KW1bcfrzIhE6cZCuwZAnM46wdm9SnlfucE12kUBW6p8bLOPJRtDBXUvAf9izI1Wnkmy55ZCmqwGQREKGu8WAedju9fdtZB5TajJGgBNQDBLGV4BZABgP1HuZCCc8kYblqF8WIF1GagfetmwjLgERaSzBfJfQ7OlqnH",
             *   "response": {
             *       "name": "Joe Doe",
             *       "email": "joe.doe@hotmail.com",
             *       "picture": {
             *       "data": {
             *           "height": 50,
             *           "is_silhouette": false,
             *           "url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=5113762757996597&height=50&width=50&ext=1663608492&hash=AeS7RmozVFhZZzhduMA",
             *           "width": 50
             *       }
             *       },
             *       "first_name": "Joe",
             *       "last_name": "Doe",
             *       "id": "5653762711996597"
             *   },
             *   "provider": "FACEBOOK"
             *   }
             */

            this.socialUser = response;
            this.userService
                .loginWithFacebook(response)
                .subscribe((response) => {
                    if (response) {
                        this.setToken(response);
                    }
                });
        });
    }

    ngAfterViewInit() {
        console.log("ngAfterViewInit");
        /**
         * Google one tap setup method
         * In this method you must set your customer ID and define the method of callback after login
         */
        // @ts-ignore
        // window.onGoogleLibraryLoad = () => {
        console.log("Google's One-tap sign in script loaded!");
        // @ts-ignore
        google.accounts.id.initialize({
            // Ref: https://developers.google.com/identity/gsi/web/reference/js-reference#IdConfiguration
            client_id: environment.tokenGoogle,
            callback: this.handleCredentialResponse.bind(this), // Whatever function you want to trigger...
            auto_select: false,
            cancel_on_tap_outside: false,
        });

        // @ts-ignore
        google.accounts.id.renderButton(
            document.getElementById("googleButton"),
            {
                type: "standard",
                text: "signin_with",
                theme: "outline",
                size: "medium",
                width: 500,
            }
        );

        // OPTIONAL: In my case I want to redirect the user to an specific path.
        // @ts-ignore
        google.accounts.id.prompt((notification: PromptMomentNotification) => {
            console.log("Google prompt event triggered...");
            /**
             * notification:  uo {g: 'dismissed', i: 'credential_returned'}
             */
            if (notification.getDismissedReason() === "credential_returned") {
                // this.ngZone.run(() => {
                //   this.router.navigate(['myapp/somewhere'], { replaceUrl: true });
                //   console.log('Welcome back!');
                // });
            }
        });
        // };
    }

    /**
     *
     * Structure for
     * response:
     * {
     *   "iss": "https://accounts.google.com",
     *   "nbf": 1660883332,
     *   "aud": "1047857322286-0pcu8es00b4vfmrrei9aqt7nq3blh4o5.apps.googleusercontent.com",
     *   "sub": "101883013406914509592",
     *   "email": "jhon.doe@gmail.com",
     *   "email_verified": true,
     *   "azp": "1047857312289-0pcu8es00x0vfmrrei9aqt7nq3blh4o5.apps.googleusercontent.com",
     *   "name": "Jhon Doe",
     *   "picture": "https://lh3.googleusercontent.com/a/hash",
     *   "given_name": "Jhon Doe",
     *   "family_name": "Doe,
     *   "iat": 1660383631,
     *   "exp": 1660387231,
     *   "jti": "de837c1ff85c52ad5235fa6f2c84682d52e2be7b"
     *   }
     * @param response
     */
    handleCredentialResponse(response: CredentialResponse) {
        // Decoding  JWT token...
        let data: any | null = null;
        try {
            data = JSON.parse(atob(response?.credential.split(".")[1]));
        } catch (e) {
            console.error("Error while trying to decode token", e);
        }
        this.userService.loginWithGoogle(data).subscribe((response) => {
            if (response) {
                this.setToken(response);
            }
        });
    }

    get f(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    onSubmit(): void {
        this.submitted = true;
        this.f.email.setValue(this.f.email.value.trim());
        this.f.password.setValue(this.f.password.value.trim());

        if (this.form.invalid) {
            return;
        }

        let token = ``;
        this.userService
            .login(
                this.f.email.value.toLowerCase().trim(),
                this.f.password.value.trim()
            )
            .subscribe((response: { expired_at: string; token: string }) => {
                if (response) {
                    this.setToken(response);
                }
            });
    }

    /* Sign In with Facebook */

    loginWithFacebook(): void {
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

    signOut(): void {
        this.socialAuthService.signOut();
    }

    setToken(response) {
        let token = response.token;
        this.userService.setTokenBearer(token);
        this.userService.getProfile(token).subscribe((response) => {
            this.userService.setUser(response.data);
            this.checkPendingPayments(token);

            this.shoppingService
                .refreshPendingPayments(token)
                .subscribe((response) => {
                    if (response.data) {
                        this.storeService.refreshPendingPayments(response.data);

                        setTimeout(() => {
                            this.redirectToHome();
                        }, 1000);
                    }
                });
        });
    }

    redirectToHome() {
        this.router.navigate(["/"], { state: { reload: true } });
        location.reload();
    }

    onReset(): void {
        this.submitted = false;
        this.form.reset();
    }

    checkCart(token: string) {
        let items = this.paymentService.getCart();
        this.paymentService.persistCart(items, token);
    }

    checkPendingPayments(token: string) {
        this.paymentService.persistPendingCart(token);
    }
}
