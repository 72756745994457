<div class="container">
    <div class="courses-price">
        <div class="price">{{ price | currency: hasQuotas }} <small class="old-price text-muted" *ngIf="hasCoupon">({{
                priceBeforeCoupon | currency }})</small>
        </div>
        <div class="price quotas" *ngIf="hasQuotas">{{ quotas }}</div>
        <hr>
    </div>
    <div class="courses-sidebar-information">
        <ul>
            <li>
                <span><i class='bx bx-folder-open'></i> Categoría:</span>
                {{ course?.category.name }}
            </li>
            <li>
                <span><i class='bx bx-group'></i> Estudiantes:</span>
                {{ course?.students_count }}
            </li>
            <li *ngIf="course?.duration">
                <span><i class='bx bx-time'></i> Duración:</span>
                {{ course.duration }}
            </li>

            <!--
                            <li>
                                <span><i class='bx bxs-institution'></i> Institution:</span>
                                <a href="#" target="_blank" class="d-inline-block">ABC</a>
                            </li> -->
            <li>
                <span><i class='bx bxs-graduation'></i> Modalidad:</span>
                {{ course?.modality | modality }}
            </li>
            <!-- <li>
                                <span><i class='bx bx-atom'></i> Quizzes:</span>
                                Yes
                            </li> -->
            <!-- <li>
                                <span><i class='bx bxs-badge-check'></i> Level:</span>
                                Introductory
                            </li> -->
            <!-- <li>
                                <span><i class='bx bx-support'></i> Language:</span>
                                English

                            </li> -->
            <!-- <li>
                                <span><i class='bx bx-text'></i> Video Subtitle:</span>
                                English
                            </li> -->
            <li>
                <span><i class='bx bx-certification'></i> Incluye certificado:</span>
                {{ course?.certificate | certificate}}
            </li>
        </ul>
    </div>

    <div class="courses-price">
        <button class="default-btn" (click)="addToCart()" *ngIf="!course?.is_purchased" [disabled]="!hasGroups"
            [ngClass]="hasGroups ? '' : 'isDisable'">
            <i class='bx bx-paper-plane icon-arrow before'></i>
            <span class="label">{{ buyButton() }}</span><i class="bx bx-paper-plane icon-arrow after"></i>
        </button>
        <br />
        <h5 *ngIf="course?.is_purchased">Ya se encuentra inscrito en el curso</h5>
        <h5 *ngIf="!course?.is_purchased && canTakeWithPlan">Acceso con suscripción</h5>

        <div class="blog-details-desc" *ngIf="course">
            <div class="article-footer">
                <div class="article-share">

                    <ul class="social">
                        <share-buttons [theme]="'material-dark'"
                            [include]="['facebook','twitter','telegram','whatsapp','copy']" [show]="5"
                            [title]="course.id" [description]="course.description_short" [url]="getUrl()"
                            [image]="getImage()" [autoSetMeta]="false"></share-buttons>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
