import { Component, OnInit, Pipe } from '@angular/core';
import { UserService } from "../../../services/user.service";
import { Router, ActivatedRoute } from '@angular/router';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { PaymentService } from "../../../services/payment.service";
import { AlertService } from '../../../services/alert.service';

@Component({
    selector: "app-change-password-page",
    templateUrl: "./change-password-page.component.html",
    styleUrls: ["./change-password-page.component.scss"],
})
export class ChangePasswordPageComponent implements OnInit {
    form: FormGroup;
    public submitted: boolean = false;
    private token: string;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private paymentService: PaymentService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private router: Router,
        ) {
        this.token = this.route.snapshot.params.token;
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            email: ["", [Validators.required, Validators.email]],
            password: [
                "",
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(40),
                ],
            ],
            password_confirmation: [
                "",
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(40),
                ],
            ],
        });

    }

    get f(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    onSubmit(): void {
        this.submitted = true;
        this.f.email.setValue(this.f.email.value.trim());
        this.f.password.setValue(this.f.password.value.trim());
        this.f.password_confirmation.setValue(this.f.password.value.trim());

        if (this.form.invalid) {
            return;
        }

        this.userService
            .selfResetPassword(
                this.f.email.value.toLowerCase().trim(),
                this.f.password.value.trim(),
                this.f.password_confirmation.value.trim(),
                this.token
            )
            .subscribe((response: { any }) => {
                this.alertService.showAlert(
                    this.alertService.typeSuccess,
                    this.alertService.passwordChangeSuccessfully
                );
                this.onReset()
                setTimeout(() => {
                    this.router.navigate(["/login"]);
                  }, 800)
            });
    }


    onReset(): void {
        this.submitted = false;
        this.form.reset();
    }

    checkCart(token: string) {
        let items = this.paymentService.getCart();
        this.paymentService.persistCart(items, token);
    }

    checkPendingPayments(token: string) {
        this.paymentService.persistPendingCart(token);
    }
}
